import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ElevateSafety } from "src/app/assets/data/constant-values";
import { CONSTANTS } from "src/app/assets/data/i18";
import { AuthService } from "src/app/shared/services/authentication/auth.service";

@Component({
  selector: "home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  navLinks = ElevateSafety.NAVIGATION_LABELS;
  constants: any = CONSTANTS;
  isRoleEmpty!: boolean;
  showData: boolean = false;
  userCode: any;
  userData: any;

  constructor(private auth: AuthService,
    private cookieService: CookieService,
    public router: Router) {  }

  ngOnInit() {
    this.getIcognitoDetails();
  }

  getIcognitoDetails(): void {
    console.log(document.URL)
    // var urls = "https://d31tdjpdmagprf.cloudfront.net/home/user-info?code=a7a92ba3-215e-46d5-a928-6c699cb1899f";
    var urls = document.URL;
    var url = new URL(urls);
    this.userCode = url.searchParams.get("code");
    console.log(this.userCode);
    if(this.userCode) {
      this.getToken()
    } else {
      this.router.navigate(["others/404"]);
    }
    
  }

  getToken() {
    const payload = {
      userName: "",
      password: "",
      code: this.userCode
    }
    this.auth.incognitoToken(payload).subscribe(
      (res) => this.setTokenAndLocalValues(res),
      (error) => {
        this.auth.clearCacheData();
        this.auth.handleError(error);
        if (this.cookieService.check(this.constants.TOKEN_HEADER)) {
          this.auth.clearCacheData();
          location.reload();
        }
      });
  }

  setTokenAndLocalValues(data: any) {
    console.log(data.body);
    data = data?.body;
    const validateUser = data?.responseStatus;
    if (validateUser?.statusCode === 200 && validateUser.statusMessage.toLowerCase() === 'authentication successful!') {
      this.userData = data?.userName;
      this.auth?.setName(this.userData);
      const date = new Date();
      date.setTime(+date + this.constants.cookieExpireTime * 3600000); // set the cookie time to 12 hours
      this.cookieService.set(this.constants.TOKEN_HEADER, data?.token, date);
      this.cookieService.set(this.constants.API_TOKEN, data?.apiAccessToken, date);
      localStorage.setItem(this.constants.USER_NAME, `${data?.userName}`);
      localStorage.setItem(this.constants.USER_ROLE, `${data?.role}`);
      localStorage.setItem(this.constants.USER_FULL_NAME, `${data?.firstName} ${data?.lastName}`);
      console.log(this.cookieService);
      console.log(localStorage);

      this.showData = true;
      const userRole = localStorage.getItem("role");
      if (userRole === "") {
        this.isRoleEmpty = true;
      } else {
        this.isRoleEmpty = false;
      }
    }
  }
}
