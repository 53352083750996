import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CommandCenterService } from "src/app/shared/services/command-center/command-center.service";
import { CONSTANTS } from "src/app/assets/data/i18";
import { takeWhile } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/authentication/auth.service";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddUserComponent implements OnInit {
  constants: any = CONSTANTS;
  userRoles: any = [];
  userManagementForm!: FormGroup;
  userDetails: any = {};
  heading: string = "";
  alive = true;

  constructor(
    private commandCenterService: CommandCenterService,
    private authService: AuthService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userDetails = data;
    this.userForm();
  }

  ngOnInit(): void {
    this.getDropdownDataFromStorage();
    this.existingUserSetValueHandeler();
    this.heading = CONSTANTS.userDetailsHeading;
  }

  userForm(): void {
    this.userManagementForm = this.fb.group({
      userRole: new FormControl("", Validators.required),
      enable: new FormControl(),
    });
  }

  get formStatus() {
    return this.userManagementForm.controls;
  }

  // for existing user set values in the forms
  existingUserSetValueHandeler(): void {
    this.userManagementForm.controls.enable.setValue(this.userDetails.enable);
    this.userManagementForm.controls.userRole.setValue(this.userDetails.role);
  }

  close(): void {
    this.dialogRef.close(this.userManagementForm);
  }

  save(): void {
    const payload = {
      username: this.userDetails.email,
      role: this.userManagementForm.value["userRole"],
      enabled: this.userManagementForm.value["enable"],
    };
    this.commandCenterService
      .updateUserDetail(payload)
      .pipe(takeWhile(() => this.alive))
      .subscribe((data: any) => {
        let modalStatus = data["body"].statusCode === 200 ? "success" : "error";
        this.authService.showSuccessErrorPopup(modalStatus, data["body"].statusMessage);
        this.dialogRef.close(modalStatus);
      });
  }

  // to get the dynamic dropdown value.
  getDropdownDataFromStorage(): void {
    let dropdownData: any = JSON.parse(localStorage.getItem(this.constants.dropdown_data) || '{}');
    this.userRoles = dropdownData?.USER_MANAGEMENT_USER_ROLES
  }
}
