<div class="document-detection systemAdminTableContainerSpacing">
  <h2 class="systemAdminTableHeading">
    {{ constants?.file_attribute_detection }}
  </h2>

  <form [formGroup]="documentDetectionForm">
    <div class="tableContainer">
      <table class="tableCSS" mat-table [dataSource]="dataSource" matSort>
        <caption></caption>
        <ng-container matColumnDef="field">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ constants?.field }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.fieldName }}</td>
        </ng-container>

        <ng-container matColumnDef="manualReview">
          <th mat-header-cell *matHeaderCellDef>
            <div class="headerWithIcon">
              <span>{{ constants?.manual_review_enabled }}</span>
              <img src="../../../app/assets/images/info-icon.svg" alt="info-icon"
                title="{{ constants?.manualReviewEnabledMSG }}" />
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox [disabled]="true" [checked]="element.manualReviewEnabled" [color]="color">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="automatedDetection">
          <th mat-header-cell *matHeaderCellDef>
            <div class="headerWithIcon">
              <span>{{ constants?.automated_review_enabled }}</span>
              <img src="../../../app/assets/images/info-icon.svg" alt="info-icon"
                title="{{ constants?.automaticDetectionEnabledMSG }}" />
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox [color]="color" [disabled]="true" [ngStyle]="
                element.automatedDetectionEnabled
                  ? { display: 'block' }
                  : { display: 'none' }
              " [checked]="element.automatedDetectionEnabled">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="lowerBound">
          <th mat-header-cell *matHeaderCellDef>
            <div class="headerWithIcon">
              <span>{{ constants?.lower_bound }}</span>
              <img src="../../../app/assets/images/info-icon.svg" alt="info-icon"
                title="{{ constants?.lowerBoundMSG }}" />
            </div>
          </th>
          <td mat-cell *matCellDef="let element; let i = index" class="systemBoundFormField">
            <mat-form-field *ngIf="
                element.manualReviewEnabled == true &&
                element.automatedDetectionEnabled == true
              " class="boundBox" appearance="outline">
              <input type="text" matInput formControlName="lowerbound_{{ i }}" [matAutocomplete]="auto"
                (change)="selectedLowerbound($event, i)" />
              <mat-icon class="arrowDownIcon" matSuffix [ngClass]="element.isEditEnable ? 'enableIcon' : 'disableIcon'">
                keyboard_arrow_down
              </mat-icon>
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                (optionSelected)="selectedLowerbound($event, i)">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="upperBound">
          <th mat-header-cell *matHeaderCellDef>
            <div class="headerWithIcon">
              <span>{{ constants?.upper_bound }}</span>
              <img src="../../../app/assets/images/info-icon.svg" alt="info-icon"
                title="{{ constants?.upperBoundMSG }}" />
            </div>
          </th>
          <td mat-cell *matCellDef="let element; let i = index" class="systemBoundFormField">
            <mat-form-field *ngIf="
                element.manualReviewEnabled == true &&
                element.automatedDetectionEnabled == true" 
              class="boundBox" appearance="outline" id="boundBoxDropIcon">
              <input type="text" matInput formControlName="upperbound_{{ i }}" [matAutocomplete]="auto" />
              <mat-icon class="arrow_down" matSuffix [ngClass]="element.isEditEnable ? 'enableIcon' : 'disableIcon'">
                keyboard_arrow_down
              </mat-icon>

              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                (optionSelected)="selectedUpperbound($event, i)">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>{{ constants?.action }}</th>
          <td mat-cell *matCellDef="let element; let i = index" class="systemBoundFormField">
            <button *ngIf="
                element.manualReviewEnabled == true &&
                element.automatedDetectionEnabled == true
              " id="editIconButton" (click)="enableRow(i, element)" [ngClass]="{
                enableEdit: element?.isEditEnable == true,
                disableEdit: element?.isEditEnable == false
              }">
              <img src="../../../app/assets/images/edit-icon-new.svg" alt="editicon" title="edit-icon" />
            </button>
            <button mat-button (click)="save(i, element)" class="saveBTN" [ngClass]="{
                enableEdit: element?.isEditEnable == false,
                disableEdit: element?.isEditEnable == true
              }">
              {{ constants?.save }}
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </form>
</div>