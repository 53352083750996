import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { takeWhile } from "rxjs/operators";
import { TableColumnNames, TaskQueueFilterInterface } from "src/app/assets/data/constant-values";
import { CONSTANTS } from "src/app/assets/data/i18";
import { DropdownLists } from "src/app/assets/data/constant-values";
import { PaginationConstants } from "src/app/assets/data/constant-values";
import { ElevateSafetyDetectiveService } from "src/app/shared/services/elevateSafety-detective/elevate-safety-detective.service";
import { AuthService } from "src/app/shared/services/authentication/auth.service";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { CommandCenterService } from "src/app/shared/services/command-center/command-center.service";
import { TaskQueueListInterface } from "src/app/assets/data/constant-values";
import { DatePipe } from "@angular/common";
@Component({
  selector: "app-task-queue",
  templateUrl: "./task-queue.component.html",
  styleUrls: ["./task-queue.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TaskQueueComponent implements OnInit {
  constants: any = CONSTANTS;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild(MatSort) sort: MatSort | any;
  @ViewChild("select") isAssigneeSelected: MatSelect | any;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = TableColumnNames.TASK_QUEUE;
  sourceTypeList:any | undefined;
  filterByTaskType:any | undefined;
  assigneeList:any = DropdownLists?.assigneeList;
  assigneeToMe:any = { name: "Assigned to me", isChecked: false};
  isFilter: boolean = false;
  resultsShow: boolean = false;
  taskQueueForm!: FormGroup;
  selectedAssignee: Array<String> = [];
  filterDictionary= new Map<string,string>();
  assigneMainList:Array<Object> = [];
  assigneeFilterHeading:string = CONSTANTS?.assignee_filter_heading;
  loggerInUser:string = '';
  //Pagination constants
  itemPerPage: number = PaginationConstants.itemPerPage;
  pageSizeOptions: number[] = PaginationConstants.pageSizeOptions;
  currentPageNumber: number = PaginationConstants.currentPageNumber;
  lastPageDataCount: number = PaginationConstants.lastPageDataCount;
  totalTask!: number;
  alive = true;
  constructor(
    fb: FormBuilder,
    private router: Router,
    public elevateSafetyDetectiveService: ElevateSafetyDetectiveService,
    public authService: AuthService,
    private commandCenterService: CommandCenterService,
  ) {
    this.taskQueueForm = fb.group({
      searchByValue: new FormControl(""),
      sourceType: new FormControl(""),
      taskType: new FormControl(""),
      searchByAssignee: new FormControl("")
    });
  }

  ngOnInit() {
    this.getDropdownDataFromStorage();
    this.getTaskQueueList();
    this.applyFilterPredicate();
    this.sortingDataAccessor();
    this.setIfFilterIsActive();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(){
    if(this.dataSource.filter != '') {
      const obj:TaskQueueFilterInterface = {
        assignee: this.assigneeFilterHeading,
        documentID: this.taskQueueForm.controls["searchByValue"].value,
        sourceType: this.taskQueueForm.controls["sourceType"].value.value,
        taskType: this.taskQueueForm.controls["taskType"].value.value,
        filterValue:this.dataSource.filter,
        filterMap: this.filterDictionary,
        activeState:false,
      }
      this.elevateSafetyDetectiveService.taskQueueFilterValues = obj;
    }
    else {
      this.elevateSafetyDetectiveService.taskQueueFilterValues = {
        assignee: "",
        documentID: "",
        sourceType: "",
        taskType: "",
        filterValue:"",
        activeState:false,
        filterMap:new Map<string,string>()
      };
    }
  }

  getDropdownDataFromStorage() : void{
    this.listOfAssignee();
    let dropdownData:any = JSON.parse(localStorage.getItem(this.constants.dropdown_data) || '{}');
    this.sourceTypeList = dropdownData?.SOURCE_TYPE;
    this.filterByTaskType = dropdownData?.TASK_TYPE;
    this.loggerInUser= localStorage.getItem(this.constants.userFullName) || '';
  }

  getTaskQueueList() : void {
    this.elevateSafetyDetectiveService
      .getTaskQueueList()
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (data) => this.taskQueueList(data?.body),
        (error) => this.authService.handleError(error)
      );
  }

  applyFilterPredicate() : void {
    this.dataSource.filterPredicate = function (record,filter) {
      let map:any = new Map(JSON.parse(filter));
      let isMatch = false;
      for(let [key,value] of map){
        if(key === 'documentID') {
          isMatch = record[key as keyof TaskQueueListInterface].trim().toLowerCase().indexOf(value) != -1;
        }
        else if(key === 'assignee') {
          isMatch = value ? value.indexOf(record[key as keyof TaskQueueListInterface]?.trim()?.toLowerCase()) != -1 : true;
        }
        else {
          isMatch = (record[key as keyof TaskQueueListInterface] == value?.value); 
        }
        if(!isMatch) return false;
      }
      return isMatch;
    }
  }

  sortingDataAccessor() : void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
         case 'lastReceiptDate': return new Date(item.createdDateTime);
         case 'priority': {
           switch (item[property]) {
             case 'Critical': return 1;
             case 'High': return 2;
             case 'Medium' : return 3;
             case 'Low' : return 4;
             default: return 5;
           }
        }
         default: return item[property];
      }
    };
  }

  // to show list of task
  taskQueueList(data: any) : void {
    this.dataSource.data = data;
    this.totalTask = this.dataSource.data.length;
    this.getPageDataCount();
  }

  dropdownSelected(ob:MatSelectChange,selectionObj:string) : void {
    this.filterDictionary.set(selectionObj,ob.value);
    this.dataSource.filter = JSON.stringify(Array.from(this.filterDictionary.entries()));
    this.showHideFilterButton(true);
  }
  
  searchByID(event: any): void {
    this.resultsShow = true;
    this.filterDictionary.set('documentID',this.taskQueueForm.value.searchByValue?.trim().toLowerCase());
    this.dataSource.filter = JSON.stringify(Array.from(this.filterDictionary.entries()));
    this.showHideFilterButton(true);
  }
  
  // navigate to detail page
  taskQueueDetails(list: any): void {
    const url = `/home/${list["documentID"]}/task-detail`;
    this.router.navigate([url]);
  }
  // to get list of users/assignee
  listOfAssignee(): void {
    this.commandCenterService.getAllUser()
      .pipe(takeWhile(() => this.alive))
      .subscribe((data: any) => {
        if (data?.body) {
          for (let i = 0; i <= data.body?.length; i++) {
            const username = `${data?.body[i]?.firstName} ${data?.body[i]?.lastName}`;
            const assignee: { name: string, isChecked: boolean } = { name: "", isChecked: false };
            assignee.name = username;
            this.assigneeList.push(assignee);
          }
        }
        this.assigneMainList = JSON.parse(JSON.stringify(this.assigneeList))
      });
  }

  // to show Assignee-lists
  openAssigneeList(): any {
    this.isAssigneeSelected?.open();
  }

  // to select/unselected particular checkbox
  optionClick(item: { name: string, isChecked: boolean }): void {
    if (item?.isChecked === false) {
      item.isChecked = true;
      this.selectedAssignee?.push(item.name);
    } else if (item?.isChecked === true) {
      item.isChecked = false;
      const index = this.selectedAssignee.indexOf(item?.name);
      if (index > -1) {
        this.selectedAssignee.splice(index, 1);
      }
    }
  }

  assignedToMe():void {
    if (this.assigneeToMe?.isChecked === false) {
      this.assigneeToMe.isChecked = true;
      this.selectedAssignee?.push(this.loggerInUser);
    } else if (this.assigneeToMe?.isChecked === true) {
      this.assigneeToMe.isChecked = false;
      const index = this.selectedAssignee?.indexOf(this.loggerInUser);
      if (index > -1) {
        this.selectedAssignee.splice(index, 1);
      }
    }
  }

  // sort table on assignee selected
  applyFilter(): any {
    this.isAssigneeSelected?.close();
    this.assigneeFilterHeading = '';
    for(let i in this.selectedAssignee) {
      const showComma = this.selectedAssignee.length > 1 ? ", ": "";
      this.assigneeFilterHeading += `${this.selectedAssignee[i]}` + showComma;
    }
    if(this.assigneeFilterHeading !=''){
      this.filterDictionary.set('assignee',this.assigneeFilterHeading.trim().toLowerCase());
      this.dataSource.filter = JSON.stringify(Array.from(this.filterDictionary.entries()));
    }
    else {
      this.assigneeFilterHeading = this.constants?.assignee_filter_heading;
      this.filterDictionary.set('assignee',"");
      this.dataSource.filter = JSON.stringify(Array.from(this.filterDictionary.entries()));
    }
    this.showHideFilterButton(true);
  }

  clearAssigneeBTN(): void {
    this.selectedAssignee = [];
    this.taskQueueForm.controls.searchByAssignee.setValue("");
    this.isAssigneeSelected.value = [];
    this.assigneeFilterHeading = this.constants?.assignee_filter_heading;
    this.assigneeList = JSON.parse(JSON.stringify(this.assigneMainList));
    this.assigneeToMe.isChecked = false;
    this.filterDictionary.set('assignee',"");
    this.dataSource.filter = JSON.stringify(Array.from(this.filterDictionary.entries()));
  }

  searchAssignee(event: any): any {
    let assigneeList: any[] = [];
    if (event?.target?.value !== "") {
      let filter = event?.target?.value.toLowerCase();
      this.assigneeList.filter((option: any) => {
        if (option.name.toLowerCase().startsWith(filter)) {
          assigneeList?.push(option);
        }
      });
      this.assigneeList = assigneeList;
    } else {
      this.assigneeList = this.assigneMainList;
    }
  }

  clearSearchField(): void{
    if(this.taskQueueForm.controls.searchByAssignee.value) {
      this.taskQueueForm.controls.searchByAssignee.setValue("");
      this.assigneeList = this.assigneMainList;
    }
  }

  // paginations
  getPage(event: any): void {
    this.totalTask = event.length;
    this.itemPerPage = event.pageSize;
    this.currentPageNumber = event.pageIndex; // to get the current page number
    this.getPageDataCount();
  }

  getPageDataCount(){
    this.dataSource["_renderData"].subscribe((ele: any) => {
      //to get records-count on last page
      this.lastPageDataCount = 0;
      this.lastPageDataCount = ele.length - 1;
    });
  }

  showHideFilterButton(value: boolean):void {
    this.resultsShow = value;
    this.isFilter = value;
  }

  clearFilter(): void {
    this.taskQueueForm.reset();
    this.clearAssigneeBTN();
    this.filterDictionary.clear();
    this.dataSource.filter = "";
    this.selectedAssignee = [];
    this.isFilter = false;
  }
  setIfFilterIsActive(): void {
    if(this.elevateSafetyDetectiveService?.taskQueueFilterValues?.activeState) {
      const obj = this.elevateSafetyDetectiveService.taskQueueFilterValues;
      this.taskQueueForm.controls['searchByValue']?.setValue(obj.documentID);
      this.taskQueueForm.controls['sourceType']?.setValue({"value":obj.sourceType});
      this.taskQueueForm.controls['taskType']?.setValue({"value":obj.taskType,"name":obj.taskType});
      this.assigneeFilterHeading = obj.assignee;
      this.filterDictionary = obj.filterMap;
      this.dataSource.filter = obj.filterValue;
      this.showHideFilterButton(true);
    }
  }
  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1.value == object2.value;
  }
}
