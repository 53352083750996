import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { mergeMap, retryWhen } from "rxjs/operators";
import { Constants } from "src/app/constants";
import { CookieService } from "ngx-cookie-service";
import { HttpClientHelper } from "src/app/app.httpclient";
import { LoaderService } from "../loader.service";
import { MatDialog } from "@angular/material/dialog";
import { ReusableDialogComponent } from "../../components/reusable-dialog/reusable-dialog.component";
import { CONSTANTS } from "src/app/assets/data/i18";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  authUrl = "auth-funnel/authenticate";
  authenticated: any;
  public name = new BehaviorSubject("");
  name$ = this.name.asObservable();

  constructor(
    public httpClientHelper: HttpClientHelper,
    public router: Router,
    public constants: Constants,
    public cookieService: CookieService,
    private loadService: LoaderService,
    public dialog: MatDialog
  ) {
    this.checkAuth();
  }

	handleError(error: any) {
    if(error?.status != 403) {
      this.showSuccessErrorPopup("error", CONSTANTS.serverError);
    }
    else {
      this.showSuccessErrorPopup("error", CONSTANTS.tokenExpire);
      this.clearCacheData();
      setTimeout(() => {
        this.router.navigate(["home"]);
      }, 3000);
    }
    this.loadService.enableLoader(false);
		return throwError(error);
	}

  checkAuth() {
    return this.cookieService?.check(this.constants.AUTHORIZATION);
  }

  hasValidToken() {
    const token = this.cookieService?.get(this.constants.AUTHORIZATION);
    if (token) {
      return true;
    } else {
      return false;
    } // TODO Based on requirements;
  }

  setName(data: any) {
    this.name.next(data);
  }

  login(data: any) {
    this.loadService?.enableLoader(true);
    return this.httpClientHelper.post(this.authUrl, data)?.pipe(
      mergeMap(val => {
        this.loadService?.enableLoader(false);
        return of(val);
      })
    );
  }

  resetUser(req: any): Observable<any> {
    const url = `user-management/complete-sign-in`;
    this.loadService.enableLoader(true);
    return this.httpClientHelper.post(url, req).pipe(
      mergeMap((val) => {
        this.loadService.enableLoader(false);
        return of(val);
      })
    );
  }

  clearCacheData() {
    this.cookieService.deleteAll();
    localStorage.clear();
    this.authenticated = false;
  }

  // to show only succes and error messages
  showSuccessErrorPopup(status: string, headingMSG: string) {
    const dialogRef = this.dialog?.open(ReusableDialogComponent, {
      id: "funnelPopupBox",
      data: {
        heading: headingMSG,
        status: status,
      },
    });
    this.closeDialogAfterThreeSeconds(dialogRef);
  }

  // close dialog after 3000-sec
  closeDialogAfterThreeSeconds(dialogBox: any) {
    dialogBox?.afterOpened().subscribe(() => {
      setTimeout(() => {
        dialogBox.close();
      }, 3000);
    });
  }

  incognitoToken(req: any): Observable<any> {
    const url = 'auth-funnel/token';
		this.loadService.enableLoader(true);
		return this.httpClientHelper.post(url, req)
			.pipe(
				mergeMap(val => {
					this.loadService.enableLoader(false);
					return of(val);
				})
			);
	}
}
