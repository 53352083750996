<div class="miscellaneous-detection systemAdminTableContainerSpacing">
  <h2 class="systemAdminTableHeading">{{ constants?.tooltips }}</h2>

  <div class="miscellaneous-table-container">
    <table>
      <caption></caption>
      <tr class="miscellaneous-table-headings">
        <th class="item-heading">{{ constants?.item }}</th>
        <th>{{ constants?.text }}</th>
        <th>{{ constants?.action }}</th>
      </tr>

      <tr class="miscellaneous-table-body">
        <td class="item-body">
          <div class="document-type-container">
            {{ constants?.case_validity_defination }}
            <img
              class="info-image"
              src="../../../app/assets/images/info-icon.svg"
              alt="info-icon"
              title="{{ constants?.tooltips }}"
            />
          </div>
        </td>
        <td>
          <input
            appCustomDirectives
            (copy)="$event.preventDefault()"
            (paste)="$event.preventDefault()"
            (cut)="$event.preventDefault()"
            class="support-info inputStyle"
            (keypress)="onkeydown($event)"
            name="casedefination"
            [(ngModel)]="miscellaneousData.caseValidityText"
            type="text"
            [disabled]="caseValidityDisable"
            placeholder="Enter Definition"
          />
        </td>
        <td>
          <button
            class="edit-icon-button"
            id="editIconButton"
            class="edit-icon"
            *ngIf="caseValidityDisable"
            (click)="enableRow('caseDefination')"
          >
            <img
              class="edit-icon"
              src="../../../app/assets/images/edit-icon-new.svg"
              alt="editicon"
            />
          </button>
          <button
            mat-button
            (click)="save('caseDefination')"
            class="saveBTN"
            *ngIf="!caseValidityDisable"
          >
            {{ constants?.save }}
          </button>
        </td>
      </tr>

      <tr class="miscellaneous-table-body">
        <td class="item-body">
          <div class="document-type-container">
            {{ constants?.product_complaint_definationa }} 
            <img
              class="info-image"
              src="../../../app/assets/images/info-icon.svg"
              alt="info-icon"
              title="{{ constants?.tooltips }}"
            />
          </div>
        </td>
        <td>
          <input
            appCustomDirectives
            (copy)="$event.preventDefault()"
            (paste)="$event.preventDefault()"
            (cut)="$event.preventDefault()"
            class="support-info inputStyle"
            name="productdefination"
            (keypress)="onkeydown($event)"
            [(ngModel)]="miscellaneousData.productionComplaintText"
            type="text"
            [disabled]="productComplaintDisable"
            placeholder="Enter Definition"
          />
        </td>
        <td>
          <button
            class="edit-icon-button"
            id="editIconButton"
            class="edit-icon"
            *ngIf="productComplaintDisable"
            (click)="enableRow('productDefination')"
          >
            <img
              class="edit-icon"
              src="../../../app/assets/images/edit-icon-new.svg"
              alt="editicon"
            />
          </button>
          <button
            mat-button
            (click)="save('productDefination')"
            class="saveBTN"
            *ngIf="!productComplaintDisable"
          >
            {{ constants?.save }}
          </button>
        </td>
      </tr>

      <tr class="miscellaneous-table-body">
        <td class="item-body">
          <div class="document-type-container">
            {{ constants?.support_information }}
            <img
              class="info-image"
              src="../../../app/assets/images/info-icon.svg"
              alt="info-icon"
              title="{{ constants?.tooltips }}"
            />
          </div>
        </td>
        <td>
          <div class="support-info-name">
            <input
              appCustomDirectives
              (copy)="$event.preventDefault()"
              (paste)="$event.preventDefault()"
              (cut)="$event.preventDefault()"
              class="support-name inputStyle"
              name="supportName"
              (keypress)="onkeydown($event)"
              [(ngModel)]="miscellaneousData.supportName"
              type="text"
              placeholder="Enter support name"
              [disabled]="supportInformationDisable"
            />
            <input
              appCustomDirectives
              (copy)="$event.preventDefault()"
              (paste)="$event.preventDefault()"
              (cut)="$event.preventDefault()"
              class="support-email inputStyle"
              name="supportEmail"
              (keypress)="onkeydown($event)"
              [(ngModel)]="miscellaneousData.supportEmail"
              type="text"
              placeholder="Enter support email"
              [disabled]="supportInformationDisable"
            />
          </div>

          <div class="support-info-instruction">
            <input
              appCustomDirectives
              (copy)="$event.preventDefault()"
              (paste)="$event.preventDefault()"
              (cut)="$event.preventDefault()"
              class="support-instruction inputStyle"
              name="supportInstruction"
              (keypress)="onkeydown($event)"
              [(ngModel)]="miscellaneousData.supportInstruction"
              type="text"
              placeholder="Enter support instruction"
              [disabled]="supportInformationDisable"
            />
          </div>
        </td>
        <td>
          <button
            class="edit-icon-button"
            id="editIconButton"
            class="edit-icon"
            *ngIf="supportInformationDisable"
            (click)="enableRow('supportInstruction')"
          >
            <img
              class="edit-icon"
              src="../../../app/assets/images/edit-icon-new.svg"
              alt="editicon"
            />
          </button>
          <button
            mat-button
            (click)="save('supportInstruction')"
            class="saveBTN"
            *ngIf="!supportInformationDisable"
          >
            {{ constants?.save }}
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>
