import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { TaskQueueComponent } from './components/task-queue/task-queue.component';
import { TaskDetailComponent } from './components/task-detail/task-detail.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { FileTrackingComponent } from './components/file-tracking/file-tracking.component';
import { ErrorComponentComponent } from './components/error-component/error-component.component';
import { AuthGuard } from './shared/services/authentication/authguard';
import { DirtyCheckGuard } from './shared/guards/dirty-check.guards';
import { CommandCenterComponent } from './components/command-center/command-center.component';
import { ReportMetricsComponent } from './components/report-metrics/report-metrics.component';
import { SystemAdministrationComponent } from './components/system-administration/system-administration.component';
import { TooltipsComponent } from './components/tooltips/tooltips.component';
import { DocumentAttributeDetectionComponent } from './components/document-attribute-detection/document-attribute-detection.component';
import { RetrospectiveQcSamplingComponent } from './components/retrospective-qc-sampling/retrospective-qc-sampling.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';

const routes: Routes = [
	// {
	// 	path: 'login',
	// 	component: LoginComponent,
	// },
	// {
	// 	path: 'reset-password',
	// 	component: ForgotPasswordComponent,
	// },
	{ 
		path: '',   
		redirectTo: 'home', 
		pathMatch: 'full' 
	},
	{
		path: 'home',
		//canActivate: [AuthGuard],
		component: HomeComponent,
		children: [
			{
				path: 'task-queue',
				component: TaskQueueComponent,
				children: []
			},
			{
				path: ':document-id/task-detail',
				component: TaskDetailComponent,
				canDeactivate: [DirtyCheckGuard],
				children: []
			},
			{
				path: 'user-info',
				component: UserInfoComponent,
				children: []
			},
			{
				path: 'file-tracking',
				component: FileTrackingComponent,
				children: []
			},
			{ 
				path: '',   
				redirectTo: 'user-info', 
				pathMatch: 'full' 
			},
		]
	},
	{
		path: 'command-center',
		//canActivate: [AuthGuard],
		component: CommandCenterComponent,
		children: [
			{
				path: 'reports-metrics',
				component: ReportMetricsComponent,
				children: []
			},
			{
				path: '',
				component: ReportMetricsComponent,
				children: []
			},
			{
				path: 'system-administration',
				component: SystemAdministrationComponent,
				children: [
					{
					path: 'file-attribute',
					component: DocumentAttributeDetectionComponent,
					children: [],
					canDeactivate: [DirtyCheckGuard]
				},{
					path: '',
					component: DocumentAttributeDetectionComponent,
					canDeactivate: [DirtyCheckGuard]
				},{
					path: 'retrospective-qc',
					component: RetrospectiveQcSamplingComponent,
					children: [],
					canDeactivate: [DirtyCheckGuard]
				},
				{
					path: 'tool-tip',
					component: TooltipsComponent,
					children: [],
					canDeactivate: [DirtyCheckGuard]
				}]
			},
			{
				path: 'user-management',
				component: UserManagementComponent,
				children: []
			},
			{
				path: 'upload-file',
				component:UploadFileComponent,
				children:[]
			},
			{
				path: 'file-tracking',
				component: FileTrackingComponent,
				children: []
			},
			{ 
				path: '',   
				redirectTo: 'user-info', 
				pathMatch: 'full' 
			},
		]
	},
	{
	  path: 'others/404',
	  component: ErrorComponentComponent
	},
	{
		path: '**',
		redirectTo: 'others/404'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
