<div class="courses-panel">
  <app-header></app-header>
  <nav mat-tab-nav-bar class="tabs" *ngIf="!isRoleEmpty">
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="link.path"
      routerLinkActive="active"
      #rla="routerLinkActive"
      [active]="rla.isActive"
    >
      {{ link.label }}
    </a>
  </nav>
</div>
<router-outlet></router-outlet>
