<div class="reportMetricsContainer">
  <div class="reportMetricsModulesName moduleHeadingList">
    <mat-action-list>
      <mat-list-item
        [ngClass]="{ listnav: clickedItem === 'reconciliation' }"
        (click)="movetoTop('reconciliation')"
      >
        {{ constant?.reconciliation }}</mat-list-item
      >
      <mat-list-item
        [ngClass]="{ listnav: clickedItem === 'adjudication' }"
        (click)="movetoTop('adjudication')"
      >
        {{ constant?.adjudication }}</mat-list-item
      >
      <mat-list-item
        [ngClass]="{ listnav: clickedItem === 'taskAging' }"
        (click)="movetoTop('taskAging')"
      >
        {{ constant?.taskAging }}</mat-list-item
      >
      <mat-list-item
        [ngClass]="{ listnav: clickedItem === 'applicationSetting' }"
        (click)="movetoTop('applicationSetting')"
        >{{ constant?.applicationSettings }}
      </mat-list-item>
    </mat-action-list>
  </div>
  <div
    class="reportMetricsModulesData systemAdminTableContainerSpacing tableContainer"
  >
    <div
      class="reconciliation"
      [ngClass]="[
        isScrolled ? 'marginTopzero' : '',
        moveReconciliation ? 'marginTopMinus215' : ''
      ]"
    >
      <div class="moduleCard">
        <div class="formLeftBlock">
          <h2 class="leftPanelHeading">{{ constant?.reconciliation }}</h2>
          <p class="leftPaneldescription">
            {{ constant?.reconciliationmessage }}
          </p>
        </div>
        <div class="formRightBlock">
          <h2 class="filterHeading">{{ constant?.Filter }}</h2>
          <div class="formFields">
            <h2 class="timePeriod">{{ constant?.timePeriod }}</h2>
            <div class="searchFilterContainer">
              <p class="searchHeading">{{ constant?.date }}</p>
              <mat-form-field
                class="boundBox reportsCalendarForm"
                appearance="outline"
              >
                <mat-date-range-input
                  [formGroup]="reconciliationForm"
                  [rangePicker]="reconciliationDatePicker"
                  [comparisonStart]="reconciliationForm.value.startDate"
                  [comparisonEnd]="reconciliationForm.value.endDate"
                >
                  <input
                    readonly
                    matStartDate
                    placeholder="Start date"
                    formControlName="startDate"
                    (click)="reconciliationDatePicker.open()"
                  />
                  <input
                    readonly
                    matEndDate
                    placeholder="End date"
                    formControlName="endDate"
                    (click)="reconciliationDatePicker.open()"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="reconciliationDatePicker"
                >
                </mat-datepicker-toggle>
                <mat-date-range-picker
                  #reconciliationDatePicker
                ></mat-date-range-picker>
              </mat-form-field>
              <button
                [disabled]="
                  !reconciliationForm.value.endDate || !reconciliationForm.valid
                "
                mat-button
                class="downloadBTN"
                (click)="downloadReportHandler('reconciliation')"
              >
                <img src="../../../app/assets/images/Vector.png" alt="" />
                {{ constant?.download }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="adjudication"
      [ngClass]="
        moveReconciliation && moveAdjudication ? 'marginTopMinus215' : ''
      "
    >
      <form [formGroup]="adjudicationForm">
        <div class="moduleCard">
          <div class="formLeftBlock">
            <h2 class="leftPanelHeading">{{ constant?.adjudication }}</h2>
            <p class="leftPaneldescription">
              {{ constant?.adjudicationmessage }}
            </p>
          </div>
          <div class="formRightBlock">
            <h2 class="filterHeading">{{ constant?.Filter }}</h2>
            <div class="formFields">
              <mat-radio-group
                class="radioTextBTN"
                formControlName="radioOptions"
              >
                <mat-radio-button
                  value="filterByTime"
                  (change)="filterValueChange($event)"
                >
                  {{ constant?.timePeriod }}
                </mat-radio-button>
                <mat-radio-button
                  value="filterById"
                  (change)="filterValueChange($event)"
                  id="filterById"
                >
                  {{ constant?.ID }}
                </mat-radio-button>
              </mat-radio-group>
              <div class="searchFilterContainer">
                <p class="searchHeading">{{ constant?.date }}</p>
                <mat-form-field
                  class="boundBox reportsCalendarForm"
                  appearance="outline"
                >
                  <mat-date-range-input
                    [rangePicker]="adjudicationDatePicker"
                    [comparisonStart]="adjudicationForm.value.startDate"
                    [comparisonEnd]="adjudicationForm.value.endDate"
                    [disabled]="selectedValue == 'filterById'"
                  >
                    <input
                      readonly
                      matStartDate
                      placeholder="Start date"
                      formControlName="startDate"
                      (click)="adjudicationDatePicker.open()"
                    />
                    <input
                      readonly
                      matEndDate
                      placeholder="End date"
                      formControlName="endDate"
                      (click)="adjudicationDatePicker.open()"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="adjudicationDatePicker"
                  >
                  </mat-datepicker-toggle>
                  <mat-date-range-picker
                    #adjudicationDatePicker
                  ></mat-date-range-picker>
                </mat-form-field>

                <div class="uplaodBox">
                  <span>
                    <p class="fileName">{{ uploadedFileName }}</p>
                  </span>
                  <input
                    #uploadFile
                    type="file"
                    hidden
                    accept=".csv"
                    (change)="uploadFileHandler($event)"
                  />
                  <button
                    class="browseBTN"
                    mat-button
                    (click)="uploadFile.click()"
                    [disabled]="selectedValue == 'filterByTime'"
                  >
                    <img src="../../../app/assets/images/Upload-icon.png" alt="" />
                    {{ constant?.upload }}
                  </button>
                </div>
                <button
                  mat-button
                  class="downloadBTN"
                  *ngIf="selectedValue === 'filterById'"
                  [disabled]="!isFileSelected && selectedValue === 'filterById'"
                  (click)="downloadReportHandler('uploadAndDownloadFile')"
                >
                  <img src="../../../app/assets/images/Vector.png" alt="" />
                  {{ constant?.download }}
                </button>
                <button
                  mat-button
                  class="downloadBTN"
                  *ngIf="selectedValue === 'filterByTime'"
                  [disabled]="!adjudicationForm.value.endDate"
                  (click)="downloadReportHandler('adjudication')"
                >
                  <img src="../../../app/assets/images/Vector.png" alt="" />
                  {{ constant?.download }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div
      class="taskaging"
      [ngClass]="
        moveReconciliation && moveAdjudication && moveTaskAging
          ? 'marginTopMinus215'
          : ''
      "
    >
      <div class="moduleCard">
        <div class="formLeftBlock">
          <h2 class="leftPanelHeading">{{ constant?.taskAging }}</h2>
          <p class="leftPaneldescription">{{ constant?.taskAgingmessage }}</p>
        </div>
        <div class="formRightBlock">
          <div class="formFields" id="taskAgingDownload">
            <div class="taskAgingDownloadBTN searchFilterContainer">
              <button
                mat-button
                class="downloadBTN"
                (click)="downloadReportHandler('taskAging')"
              >
                <img src="../../../app/assets/images/Vector.png" alt="" />
                {{ constant?.download }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="applicationSetting"
      [ngClass]="
        moveReconciliation && moveAdjudication && moveTaskAging ? 'mt-55' : ''
      "
    >
      <div class="moduleCard">
        <div class="formLeftBlock">
          <h2 class="leftPanelHeading">{{ constant?.applicationSettings }}</h2>
          <p class="leftPaneldescription">
            {{ constant?.applicationSettingsmessage }}
          </p>
        </div>
        <div class="formRightBlock">
          <h2 class="filterHeading">{{ constant?.Filter }}</h2>
          <div class="formFields">
            <h2 class="timePeriod">{{ constant?.timePeriod }}</h2>
            <div class="searchFilterContainer">
              <p class="searchHeading">{{ constant?.date }}</p>
              <mat-form-field
                class="boundBox reportsCalendarForm"
                appearance="outline"
              >
                <mat-date-range-input
                  [formGroup]="applicationSettingsForm"
                  [rangePicker]="applicationSettingsDatePicker"
                  [comparisonStart]="applicationSettingsForm.value.startDate"
                  [comparisonEnd]="applicationSettingsForm.value.endDate"
                >
                  <input
                    readonly
                    matStartDate
                    placeholder="Start date"
                    formControlName="startDate"
                    (click)="applicationSettingsDatePicker.open()"
                  />
                  <input
                    readonly
                    matEndDate
                    placeholder="End date"
                    formControlName="endDate"
                    (click)="applicationSettingsDatePicker.open()"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="applicationSettingsDatePicker"
                >
                </mat-datepicker-toggle>
                <mat-date-range-picker
                  #applicationSettingsDatePicker
                ></mat-date-range-picker>
              </mat-form-field>
              <button
                mat-button
                [disabled]="
                  !applicationSettingsForm.value.endDate ||
                  !applicationSettingsForm.valid
                "
                class="downloadBTN"
                (click)="downloadReportHandler('applicationSettings')"
              >
                <img src="../../../app/assets/images/Vector.png" alt="" />
                {{ constant?.download }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
