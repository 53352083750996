<div class=" systemAdminTableContainerSpacing" id="retrospectiveSampling">
  <h2 class="systemAdminTableHeading">{{constants?.retrospective_qc_sampling}}</h2>

  <form [formGroup]="retrospectiveQualityForm">
    <div class="RQStable tableContainer">
      <table class="tableCSS" mat-table [dataSource]="dataSource" matSort>
        <caption></caption>
        <!-- Document Type column -->
        <ng-container matColumnDef="documentType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{constants?.document_type}}</th>
          <td mat-cell *matCellDef="let element">{{ element.documentType }}</td>
        </ng-container>

        <!-- Frequency column -->
        <ng-container matColumnDef="frequency">
          <th mat-header-cell *matHeaderCellDef>
            <div class="headerWithIcon">
              <span>{{constants?.frequency}}</span>
              <img src="../../../app/assets/images/info-icon.svg" alt="info-icon" title={{constants?.frequencyMSG}}>
            </div>
          </th>
          <td mat-cell *matCellDef="let element; let i = index" class="systemBoundFormField frequencyInputForm">
            <mat-form-field class="boundBox" appearance="outline">
              <mat-select placeholder="Choose option" (selectionChange)="selectedRQCFrequency($event.value, i)"
                formControlName="frequency_{{ i }}" single>
                <mat-option *ngFor="let type of frequencyList" [value]="type">
                  {{ type}}
                </mat-option>
              </mat-select>
              <mat-icon class="arrow_down arrowDownIcon" matSuffix
                [ngClass]="element.isEditEnable ? 'enableIcon' : 'disableIcon'">
                keyboard_arrow_down
              </mat-icon>
            </mat-form-field>
          </td>
        </ng-container>

        <!-- Period end column -->
        <ng-container matColumnDef="periodStart">
          <th mat-header-cell *matHeaderCellDef>
            <div class="headerWithIcon">
              <span>{{constants?.period_start}}</span>
              <img src="../../../app/assets/images/info-icon.svg" alt="info-icon" title={{constants?.periodEndMSG}}>
            </div>
          </th>
          <td mat-cell *matCellDef="let element; let i = index" class="systemBoundFormField periodStartInputForm">
            <mat-form-field class="boundBox" appearance="outline">
              <mat-select placeholder="Choose option" formControlName="periodEnd_{{ i }}" single>
                <mat-option *ngFor="let type of periodboundList" [value]="type">
                  {{ type}}
                </mat-option>
              </mat-select>
              <mat-icon class="arrow_down arrowDownIcon" matSuffix [ngClass]="{
                'enableIcon': (element.isEditEnable === true) &&
                (element.frequency === 'Weekly' || element.frequency === 'Biweekly'),
                'disableIcon': (element.isEditEnable === false) || 
                (element.frequency === 'Daily' || element.frequency === 'Monthly')
              }">
                keyboard_arrow_down
              </mat-icon>
            </mat-form-field>
          </td>
        </ng-container>
        
        <!-- Sampling Rate column -->
        <ng-container matColumnDef="samplingRate">
          <th mat-header-cell *matHeaderCellDef>
            <div class="headerWithIcon">
              <span>{{constants?.sampling_rate}}</span>
              <img src="../../../app/assets/images/info-icon.svg" alt="info-icon" title={{constants?.samplingRateMSG}}>
            </div>
          </th>
          <td mat-cell *matCellDef="let element; let i = index" class="systemBoundFormField samplingRateInputForm">
            <mat-form-field class="boundBox" appearance="outline">
              <mat-select placeholder="Choose option" formControlName="samplingRate_{{ i }}" single>
                <mat-option *ngFor="let type of samplingboundList" [value]="type">
                  {{ type}}
                </mat-option>
              </mat-select>

              <mat-icon class="arrow_down arrowDownIcon" matSuffix
                [ngClass]="element.isEditEnable ? 'enableIcon' : 'disableIcon'">
                keyboard_arrow_down
              </mat-icon>
            </mat-form-field>
          </td>
        </ng-container>

        <!-- Edit/Save column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>{{constants?.action}} </th>
          <td mat-cell *matCellDef="let element; let i = index" class="systemBoundFormField">
            <!-- Edit button -->
            <button id="editIconButton" *ngIf="element.isEditEnable == false" (click)="enableRow(i, element)"
              [ngClass]="{enableEdit: element?.isEditEnable == true, disableEdit: element?.isEditEnable == false}">
              <img src="../../../app/assets/images/edit-icon-new.svg" alt="editicon" title="edit-icon" />
            </button>
            <!-- Save button -->
            <button mat-button (click)="save(i, element)" class="saveBTN"
              [ngClass]="{ enableEdit: element?.isEditEnable == false, disableEdit: element?.isEditEnable == true }">
              {{ constants?.save }}
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </form>
</div>
