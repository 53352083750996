// list of table-columns through-out the application
export class TableColumnNames {
  static TASK_QUEUE = [
    "documentID",
    "lastReceiptDate",
    "priority",
    "sourceType",
    "assignee",
    "taskType",
  ];
  static FILE_TRACKING = [
    "downloadInBound",
    "downloadOutBound",
    "fileName",
    "documentID",
    "receiptDate",
    "documentType",
    "INT",
    "AA",
    "FR",
    "T",
    "RQ",
    "safetyTransmission",
    "qualityTransmission",
  ];
  static USER_MANAGEMENT_TABLE = [
    "username",
    "email",
    "role",
    "enable",
    "edit",
  ];
  static FILE_ATTRIBUTE_DETECTION = [
    "field",
    "manualReview",
    "automatedDetection",
    "lowerBound",
    "upperBound",
    "action",
  ];
  static RESTROSPECTIVE_QUALITY_SAMPLING = [
    "documentType",
    "frequency",
    "periodStart",
    "samplingRate",
    "action",
  ];
  static TOOLTIPS = ["item", "text", "action"];
}

// list of dropdown's used through-out the application.
export class DropdownLists {
  static SOURCE_TYPE = [{ value: "US HCP Chatbot", name: "US HCP Chatbot" }];

  static DOCUMENT_TYPE = [{ value: "US HCP Chatbot", name: "US HCP Chatbot" }];

  static TASK_TYPE = [
    { value: "fileReview", name: "File Review" },
    { value: "retrospectiveQuality", name: "Retrospective Quality" },
  ];
  static USER_MANAGEMENT_USER_ROLES = [
    { name: "CASE PROCESSOR", value: "CASE_PROCESSOR" },
    { name: "SYSTEM ADMINISTRATOR", value: "SYSTEM_ADMINISTRATOR" },
  ];

  static FILE_ATTRIBUTE_DETECTION_INTERFACE = [
    {
      fieldName: "",
      lowerbound: "",
      upperbound: "",
      attributeDetectionId: 0,
      automatedEnabled: false,
      manualEnabled: false,
      isEditEnable: false,
    },
  ];

  static RETROSPECTIVE_QUALITY_INTERFACE = [
    {
      documentType: "",
      frequency: "",
      periodStart: "",
      rpcId: 0,
      samplingRate: 0,
      isEditEnable: false,
    },
  ];

  static assigneeList = [
    { name: "Unassigned", isChecked: false },
  ]
}

// Pagiations constants
export class PaginationConstants {
  static itemPerPage = 10;
  static currentPageNumber = 0;
  static lastPageDataCount = 9;
  static pageSizeOptions = [10, 25, 50, 100];
}

// command-center navbar headers with routing links
export class CommandCenter {
  static NAVIGATION_LABELS_FOR_CP = [
    { path: "reports-metrics", label: "Reports and Metrics" },
  ];
  static NAVIGATION_LABELS = [
    { path: "reports-metrics", label: "Reports and Metrics" },
    { path: "system-administration", label: "System Administration" },
    { path: "user-management", label: "User Management" },
    {path: "upload-file", label: "Upload File"},
  ];
}

// elevate-safety navbar headers with routing links
export class ElevateSafety {
  static NAVIGATION_LABELS = [
    { path: "task-queue", label: "Task Queue" },
    { path: "file-tracking", label: "File Tracking" },
  ];

  static TASK_VIEWER_NAVIGATION_LABELS = [
    { url: "../../../app/assets/images/magnifying-icon.svg", value: "case-narrative", label: "Case Narrative" },
    { url: "../../../app/assets/images/document-icon.svg", value: "full-source-document", label: "Full Source Document" },
    { url: "../../../app/assets/images/attachments-icon.svg", value: "attachments", label: "Attachments" },
  ];
}

//File Review Entity Data
export class EntityData {
  static FILE_REVIEW_DATA = {
    eventDetectionForm: {
      field: "Safety Information Detection > Safety Information Detection > ",
      detected: "Safety Information  Detected",
      notDetected: "No Safety Information  Detected",
      fieldRequestValue: "isEventDetected",
    },
    // patientDetectionForm: {
    //   field: "Event Detection > Patient Detection > ",
    //   detected: "Patient Detected",
    //   notDetected: "No Patient Detected",
    //   fieldRequestValue: "isPatientDetected",
    // },
    // reporterDetectionForm: {
    //   field: "Event Detection > Reporter Detection > ",
    //   detected: "Reporter Detected",
    //   notDetected: "No Reporter Detected",
    //   fieldRequestValue: "isReporterDetected",
    // },
    
    // companyProductDetected: {
    //   field: "Event Detection > Suspect Product Detection > ",
    //   detected: "Company Product Detected",
    //   notDetected: "No Company Product Detected",
    //   fieldRequestValue: "companyProductDetected",
    // },
    // noSuspectProductDetected: {
    //   field: "Event Detection > Suspect Product Detection > ",
    //   detected: "Other Manufacture Product Detected",
    //   fieldRequestValue: "Other Manufacture Product Detected",
    // },
    // otherManufacturerProductDetected: {
    //   field: "Event Detection > Suspect Product Detection > ",
    //   detected: "Unknown if Company Product Detected",
    //   fieldRequestValue: "Unknown if Company Product Detected",
    // },
    // unknownifCompanyProductDetected: {
    //   field: "Event Detection > Suspect Product Detection > ",
    //   detected: "No Suspect Product Detected",
    //   fieldRequestValue: "No Suspect Product Detected",
    // },
    productComplainaintForm: {
      field:
        "Product Quality Complaint Detection > Product Quality Complaint Detection > ",
      detected: "Product Quality Complaint Detected",
      notDetected: "No Product Quality Complaint Detected ",
      fieldRequestValue: "isProductQualityComplaint",
    },
  };

  static DISABLE_FORM_FLAGS = {
    eventDetectionForm: true,
    // patientDetectionForm: true,
    // reporterDetectionForm: true,
    // suspectProductDetectionForm: true,
    //As Per Changes Request in CHS-FNL-3815
    productComplainaintForm: true,
  };
  static ENABLE_FORM_FLAGS = {
    eventDetectionForm: false,
    // patientDetectionForm: false,
    // reporterDetectionForm: false,
    // suspectProductDetectionForm: false,
    //As Per Changes Request in CHS-FNL-3815
    productComplainaintForm: false,
  };

  static TASK_DETAIL_RESPONSE = {
    isClaimed: false,
    taskId: "",
    caseValidityCriteria: {
      isEventDetected: null,
      // suspectProductDetection: {
      //   companyProductDetected: null,
      //   unknownifCompanyProductDetected: null,
      //   noSuspectProductDetected: null,
      //   otherManufacturerProductDetected: null,
      // },
      // isPatientDetected: null,
      // isReporterDetected: null,
      //As Per Changes Request in CHS-FNL-3815
      isProductQualityComplaint: null,
    },
    assignee: "",
    taskType: "",
    information: {
      PRODUCT_QUALITY_DEFINITION: "",
      CASE_VALIDITY_DEFINITION: "",
    },
    transcriptsUrl: "",
    detectedAs: "",
  };
}

export interface TaskQueueListInterface {
  assignedOn: string;
  assignee: string;
  createdDateTime: string;
  documentID: string;
  id: string;
  incomingFileCreatedOn: string;
  lastReceiptDate: string;
  previousAssignee: string;
  priority: string;
  sourceType: string;
  taskCreatedOn: string;
  taskType: string;
}

export interface FileTrackingListInterface {
  chatTranscriptsLocation: string
  documentID: string
  fileName: string
  fileStages: string
  inboundUrl: string
  isDownloadable: string
  outboundUrl: string
  qualityTransmission: string
  receiptDate: string
  safetyTransmission: string
  source: string
}

export interface UserManagementInterface {
  email: string
  enable: string
  username: string
  firstName: string
  lastName: string
  phoneNumber: string
  role: string
  edit: string
}

export interface TaskQueueFilterInterface {
  assignee: string;
  documentID: string;
  sourceType: string;
  taskType: string;
  filterValue:string;
  activeState:boolean;
  filterMap:Map<string,string>;
}

