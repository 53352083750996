import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { delay, mergeMap, retryWhen, take } from "rxjs/operators";
import { HttpClientHelper } from "src/app/app.httpclient";
import { AuthService } from "../authentication/auth.service";
import { LoaderService } from "../loader.service";

@Injectable({
  providedIn: "root",
})
export class CommandCenterService {
  constructor(
    private httpClient: HttpClientHelper,
    private authService: AuthService,
    private loadService: LoaderService
  ) {}
  adminUrl = `funnel/admin/`;
  reportUrl = `funnel/reports/`;
  userManagementUrl = `user-management/`;

  //File Attributes
  getDocumentListAttrs(): Observable<any> {
    this.loadService.enableLoader(true);
    return this.httpClient
      .getS(this.adminUrl + `fileAttributeDetection/get`)
      .pipe(
        mergeMap((val) => {
          this.loadService.enableLoader(false);
          return of(val);
        })
      );
  }

  saveDocumentAttrs(req: any): Observable<any> {
    this.loadService.enableLoader(true);
    return this.httpClient
      .postS(this.adminUrl + `fileAttributeDetection/update`, req)
      .pipe(
        mergeMap((val) => {
          this.loadService.enableLoader(false);
          return of(val);
        })
      );
  }

  //Tool Tips
  getMiscellaneousData(): Observable<any> {
    this.loadService.enableLoader(true);
    return this.httpClient.getS(this.adminUrl + `tooltips/get`).pipe(
      mergeMap((val) => {
        this.loadService.enableLoader(false);
        return of(val);
      })
    );
  }
  
  saveMiscellaneousData(req: any): Observable<any> {
    this.loadService.enableLoader(true);
    return this.httpClient
      .postS(this.adminUrl + `tooltips/update`, req)
      .pipe(
        mergeMap((val) => {
          this.loadService.enableLoader(false);
          return of(val);
        })
      );
  }

  //Reports and Metrics
  getApplicationSettings(startdate: any, enddate: any): Observable<any> {
    return this.httpClient
      .getCSV(this.reportUrl + `applicationSettings/${startdate}/${enddate}`)
      .pipe(
        mergeMap((val) => {
          return of(val);
        })
      );
  }

  downloadReconciliationReport(startDate: any, endDate: any): Observable<any> {
    const url = `funnel/reports/recounciliation/${startDate}/${endDate}`;
    return this.httpClient.getCSV(url).pipe(
      mergeMap((val) => {
        return of(val);
      })
    );
  }

  downloadAdjudicationReport(startDate: any, endDate: any): Observable<any> {
    const url = `funnel/reports/adjudication/${startDate}/${endDate}`;
    return this.httpClient.getCSV(url).pipe(
      mergeMap((val) => {
        return of(val);
      })
    );
  }

  postadjudication(file: any): Observable<any> {
    return this.httpClient
      .uploadFile(this.reportUrl + `adjudication/uploadFile`, file)
      .pipe(
        mergeMap((val) => {
          return of(val);
        })
      );
  }

  downloadTaskAgingReport(): Observable<any> {
    const url = `funnel/reports/taskAging`;
    return this.httpClient.getCSV(url).pipe(
      mergeMap((val) => {
        return of(val);
      })
    );
  }

  //RetrospectiveQc
  getListOfFrequency(): Observable<any> {
    return this.httpClient.getS(this.adminUrl + `retrospectiveQuality/get`).pipe(
      mergeMap((val) => {
        return of(val);
      })
    );
  }
  
  postListOfFrequency(obj: any): Observable<any> {
    return this.httpClient
      .postS(this.adminUrl + `retrospectiveQuality/update`, obj)
      .pipe(
        mergeMap((val) => {
          return of(val);
        })
      );
  }

  //Adjudication
  getadjudication(): Observable<any> {
    return this.httpClient.getCSV(this.reportUrl + `adjudication`).pipe(
      mergeMap((val) => {
        return of(val);
      })
    );
  }

  adjudicationAuditLogs(obj: any): Observable<any> {
    return this.httpClient.postS(`funnel/adjudication/auditLog`, obj).pipe(
      mergeMap((val) => {
        return of(val);
      })
    );
  }

  //User Management
  updateUserDetail(req: any): Observable<any> {
    const url = `${this.userManagementUrl}update-user-details`;
    this.loadService.enableLoader(true); 
    return this.httpClient.postS(url, req).pipe(
      mergeMap((val) => {
        this.loadService.enableLoader(false);
        return of(val);
      })
    );
  }

  changeUserStatus(req: any): Observable<any> {
    const url = `${this.userManagementUrl}change-user-account-status`;
    this.loadService.enableLoader(true);
    return this.httpClient.postS(url, req).pipe(
      mergeMap((val) => {
        this.loadService.enableLoader(false);
        return of(val);
      }),
      // retryWhen((error) => {
      //   this.loadService.enableLoader(false);
      //   return of(error);
      // })
    );
  }

  getAllUser(): Observable<any> {
    return this.httpClient
      .postS(`${this.userManagementUrl}list-all-users`)
      .pipe(
        mergeMap((val) => {
          return of(val);
        }),
        // retryWhen((error) => {
        //   return of(error);
        // })
      );
  }
  downloaduploadfiles(file:any):Observable<any>{
    return this.httpClient.uploadTask('s3/uploadFile',file).pipe(
      mergeMap(val =>{
        return of (val);
      }),
      // retryWhen(error => error.pipe(delay(3000), take(3)))
      );
  }
}
