<div class="taskQueueContainer containerPadding">
  <form [formGroup]="taskQueueForm">
    <div class="searchClearBox">
      <div class="searchActions searchFilterContainer">
        <span class="searchBox mr_19">
          <p class="searchHeading">{{ constants?.id }}</p>
          <mat-form-field class="example-form-field" appearance="outline">
            <input matInput type="text" placeholder="Search by ID" (keyup)="searchByID($event)"
              formControlName="searchByValue" />
            <img src="../../../app/assets/images/search-icon.svg" alt="search" height="15px" width="15px"
              title="search" />
          </mat-form-field>
        </span>

        <span class="dropdown_docType mr_19">
          <p class="searchHeading">{{ constants?.source }}</p>
          <mat-form-field appearance="outline" class="dropdown_icon">
            <mat-select (selectionChange)="dropdownSelected($event,'sourceType')" 
            [formControlName]="'sourceType'" [compareWith]="compareObjects"
              [placeholder]="constants?.select_document_type">
              <mat-option *ngFor="let type of sourceTypeList" [value]="type">
                {{ type.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>

        <span class="dropdown_taskType mr_19">
          <p class="searchHeading">{{ constants?.task_type }}</p>
          <mat-form-field appearance="outline" class="dropdown_icon">
            <mat-select (selectionChange)="dropdownSelected($event,'taskType')" placeholder="Select task type(s)"
              [formControlName]="'taskType'" [compareWith]="compareObjects">
              <mat-option *ngFor="let type of filterByTaskType" [value]="type">
                {{ type.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>

        <span id="assignee-filter-box" class="searchFilterContainer assignee-filter-box">
          <button mat-button (click)="openAssigneeList()" title="{{ assigneeFilterHeading }}">
            <label>
              {{ assigneeFilterHeading }}
            </label>
          </button>
          <mat-form-field id="attributeInputHidden">
            <mat-select #select multiple id="matSelects">
              <mat-form-field class="assignee-form-field">
                <input matInput type="text" placeholder="Search users" (keyup)="searchAssignee($event)" 
                formControlName="searchByAssignee">
                  <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchField()">
                    <mat-icon>close</mat-icon>
                  </button>
              </mat-form-field>
              <div class="assignee-options-list">
                <mat-option (click)="assignedToMe()" [value]="assigneeToMe.name">
                  {{ assigneeToMe?.name}}
                </mat-option>
                <mat-option (click)="optionClick(list)" *ngFor="let list of assigneeList; let i = index" [value]="list?.name">
                  {{ list?.name }}
                </mat-option>
              </div>
              <div class="apply-btn">
                <button mat-button class="applyFilterBTN" (click)="applyFilter()">
                  {{ constants?.apply }}
                </button>
                <button mat-button class="clearAssigneeBTN" (click)="clearAssigneeBTN()">
                  {{ constants?.clear }}
                </button>
              </div>
            </mat-select>
          </mat-form-field>
        </span>
      </div>

      <div class="clearBTN" *ngIf="isFilter" (click)="clearFilter()">
        {{ constants?.clear_filters }}
      </div>
    </div>
  </form>

  <div class="tableContainer">
    <table class="tableCSS" mat-table [dataSource]="dataSource" matSort>
      <caption></caption>
      <ng-container matColumnDef="documentID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ constants?.id }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.documentID }}</td>
      </ng-container>

      <ng-container matColumnDef="lastReceiptDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ constants?.intake_date }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdDateTime | date: "dd-MMM-yyyy hh:mm:ss" | uppercase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ constants?.priority }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.priority }}</td>
      </ng-container>

      <ng-container matColumnDef="sourceType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ constants?.source }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.sourceType }}</td>
      </ng-container>

      <ng-container matColumnDef="assignee">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ constants?.assignee }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="assigneeType">{{ element.assignee }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="taskType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ constants?.task_type }}
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ element.taskType }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="taskQueueDetails(row)"></tr>
    </table>
  </div>

  <div class="paginationRow">
    <div *ngIf="!resultsShow; else elseBlock" id="pageNumber">
      <p>
        {{ constants?.showing }} {{ lastPageDataCount + 1 }}
        {{ constants?.of }} {{ totalTask }} {{ constants?.results }}
      </p>
      <p>Page No: {{ currentPageNumber + 1 }}</p>
    </div>
    <ng-template #elseBlock>
      <p>
        {{ constants?.showing }} {{ this.dataSource.filteredData.length }}
        {{ constants?.of }} {{ this.dataSource.filteredData.length }}
        {{ constants?.results }}
      </p>
    </ng-template>
    <mat-paginator #paginator [length]="totalTask" [pageSize]="10" [pageSizeOptions]="pageSizeOptions"
      (page)="getPage($event)">
    </mat-paginator>
  </div>
</div>