import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { takeWhile } from "rxjs/operators";
import { CONSTANTS } from "src/app/assets/data/i18";
import { AuthService } from "src/app/shared/services/authentication/auth.service";
import { ElevateSafetyDetectiveService } from "src/app/shared/services/elevateSafety-detective/elevate-safety-detective.service";

@Component({
  selector: "app-task-detail",
  templateUrl: "./task-detail.component.html",
  styleUrls: ["./task-detail.component.scss"],
})
export class TaskDetailComponent implements OnInit {
  constants: any = CONSTANTS;
  private alive = true;
  isCriteriaCollapsed!: boolean;
  taskDetails: [] | any;
  documentId: any;
  isStatusClamied!: boolean;
  isDirty!: boolean;

  constructor(
    public elevateSafetyDetectiveService: ElevateSafetyDetectiveService,
    public authService: AuthService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.documentId = this.route.snapshot.paramMap.get("document-id");
    this.getTaskDetails();
    if (this.elevateSafetyDetectiveService.taskQueueFilterValues.filterValue == '') {
      this.elevateSafetyDetectiveService.taskQueueFilterValues.activeState = false;
    }
    else {
      this.elevateSafetyDetectiveService.taskQueueFilterValues.activeState = true;
    }
  }

  columnCollapsed(event: any): void {
    this.isCriteriaCollapsed = event;
  }

  recallTaskDetail(): void {
    this.getTaskDetails();
  }

  getTaskDetails() {
    this.elevateSafetyDetectiveService
      .getTaskQueueDetails(this.documentId)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (data) => this.showData(data?.body),
        (error) => this.authService.handleError(error)
      );
  }

  showData(data: any) {
    this.taskDetails = data;
  }

  claimedStatus(event: boolean): void {
    this.isStatusClamied = event;
    this.getTaskDetails();
  }

  isEditEnable(event: boolean): void {
    this.isDirty = event;
  }

  canDeactivate() {
    return this.isDirty;
  }
}
