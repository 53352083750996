import { Component, ViewEncapsulation, OnInit, AfterViewInit } from "@angular/core";
import { LoaderService } from "./shared/services/loader.service";
import { CONSTANTS } from "./assets/data/i18";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterViewInit {
  showHideLoader!: boolean;
  appTitle: string = CONSTANTS?.appTitle;
  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    // do nothing.
  }

  ngAfterViewInit(): void {
    this.loaderService.$isLoading.subscribe((load: any) => {
      setTimeout(() => (this.showHideLoader = load));
    });
  }
}
