import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CONSTANTS } from "src/app/assets/data/i18";
import { AuthService } from "src/app/shared/services/authentication/auth.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  constants: any = CONSTANTS;
  showLogout: boolean = false;
  isRoleEmpty!:boolean;

  constructor(private router: Router, public auth: AuthService) {}

  ngOnInit(): void {
    //do nothing const userRole = localStorage.getItem('role');
    const userRole = localStorage.getItem('role');  
    if(userRole === '') {
      this.isRoleEmpty = true;
    } else {
      this.isRoleEmpty = false;
    }
  }

  logout() {
    this.auth.clearCacheData();
    this.login();
  }

  login() {
    localStorage.removeItem("username");
    this.router.navigate(["login"]);
  }

  navigateToHomeModule(): void { // elevate-safety
    this.router.navigate(["home"]);
  }

  navigateToAdminModule(): void { // command-center
    this.router.navigate(["command-center", "reports-metrics"]);
  }

  navigateToNewTab(){
    let dropdownData:any = JSON.parse(localStorage.getItem(this.constants.dropdown_data) || '{}');
    window.open(dropdownData?.HELP_DOCUMENT , '_blank');
  }
}
