import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap} from 'rxjs/operators';
import { HttpClientHelper } from 'src/app/app.httpclient';
import { LoaderService } from '../loader.service';
import { AuthService } from '../authentication/auth.service';
import { TaskQueueFilterInterface } from 'src/app/assets/data/constant-values';
@Injectable({
	providedIn: 'root'
})
export class ElevateSafetyDetectiveService {
	taskDetailUrl = `funnel/taskDetail/`;
	userManagementUrl = `user-management`;
	taskQueueFilterValues:TaskQueueFilterInterface = {
		assignee: "",
		documentID: "",
		sourceType: "",
		taskType: "",
		filterValue:"",
		activeState:false,
		filterMap:new Map<string,string>()
	};
	constructor(
		private httpClient: HttpClientHelper,
		public loadService: LoaderService,
		private authService: AuthService
	) { }

	getTaskQueueList(): Observable<any> {
		this.loadService.enableLoader(true);
		return this.httpClient.get(`funnel/taskQueue/all`).pipe(
			mergeMap(val => {
				this.loadService.enableLoader(false);
				return of(val);
			})
		);
	}

	getFilterTrackingData(): Observable<any> {
		this.loadService.enableLoader(true);
		return this.httpClient.get(`funnel/file-tracking/all`).pipe(
			mergeMap((val) => {
				this.loadService.enableLoader(false);
				return of(val);
			})
		);
	}

	getDropdownData(): Observable<any> {
		this.loadService.enableLoader(true);
		return this.httpClient.get(`funnel/dropdowndata/get`).pipe(
			mergeMap(val => {
				this.loadService.enableLoader(false);
				return of(val);
			})
		);
	}
	
	getTaskQueueDetails(documentID: any): Observable<any> {
		this.loadService.enableLoader(true);
		return this.httpClient.get(this.taskDetailUrl + documentID).pipe(
			mergeMap(val => {
				this.loadService.enableLoader(false);
				return of(val);
			})
		);
	}

	getTaskChatBotTranscripts(documentID: any): Observable<any> {
		this.loadService.enableLoader(true);
		return this.httpClient.get(this.taskDetailUrl + `chatTransc/` + documentID).pipe(
			mergeMap(val => {
				this.loadService.enableLoader(false);
				return of(val);
			})
		);
	}

	// TOFIX: new task viewer UI from client
	chatDetails(documentID: any): Observable<any> {
		const url = `chatDetails/${documentID}`;
		this.loadService.enableLoader(true);
		return this.httpClient.get(this.taskDetailUrl + url).pipe(
			mergeMap(val => {
				this.loadService.enableLoader(false);
				return of(val);
			})
		);
	}

	claimOrUncliamTask(documentID: string, status: string): Observable<any> {
		this.loadService.enableLoader(true);
		return this.httpClient.postH(this.taskDetailUrl + `claim/` + documentID + `/` + status).pipe(
			mergeMap(val => {
				this.loadService.enableLoader(false);
				return of(val);
			})
		);
	}

	completeTask(documentID: string, req: any): Observable<any> {
		this.loadService.enableLoader(true);
		return this.httpClient.post(this.taskDetailUrl + `completeTask/` + documentID, req)
			.pipe(
				mergeMap(val => {
					this.loadService.enableLoader(false);
					return of(val);
				})
			);
	}

	// to send verification-code to mail-id of the existing user
	sendResetPassword(data: any): Observable<any> {
		const url = `${this.userManagementUrl}/forgot-password`;
		this.loadService.enableLoader(true);
		return this.httpClient.postH(url, data).pipe(mergeMap(val => {
			this.loadService.enableLoader(false);
			return of(val);
		})
		);
	}

	// to reset password of the existing user
	resetPassword(data: any): Observable<any> {
		const url = `${this.userManagementUrl}/reset-password`;
		this.loadService.enableLoader(true);
		return this.httpClient.postH(url, data).pipe(mergeMap(val => {
			this.loadService.enableLoader(false);
			return of(val);
		})
		);
	}
}
