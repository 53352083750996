<div class="reset-password-container">
    <div class="funnel-header"> {{constant?.elevateSafety_detective}}</div>

    <div class="reset-form-container" id="reset-container">
        <mat-card class="card">
            <mat-card-title class="funnel-title">{{constant?.reset_password}}</mat-card-title>
            <mat-card-content>
                <form [formGroup]="resetForm" class="d-flex-center">
                    <div class="example-container">
                        <div class="input-form">
                            <mat-form-field>
                                <label>{{constant?.email}}</label>
                                <input matInput formControlName="userName">
                                <mat-error *ngIf="!resetForm.get('userName')?.errors?.required && 
                                (resetForm.get('userName')?.errors?.pattern)">
                                    {{constant?.errorMsg_email}}
                                </mat-error>
                                <mat-error *ngIf="resetForm.get('userName')?.invalid && 
                                (resetForm.get('userName')?.dirty || 
                                resetForm.get('userName')?.touched) && 
                                resetForm.get('userName')?.errors?.required">
                                    {{constant?.email_required}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div *ngIf="isOTPSent">
                            <span class="input-form">
                                <mat-form-field>
                                    <label>{{constant?.password_received}}</label>
                                    <input matInput formControlName="resetCode">
                                    <mat-error *ngIf="resetForm.get('resetCode')?.invalid && 
                                    (resetForm.get('resetCode')?.dirty || 
                                    resetForm.get('resetCode')?.touched) && 
                                    resetForm.get('resetCode')?.errors?.required">
                                        {{constant?.OTPErrorMsg}}
                                    </mat-error>
                                </mat-form-field>
                            </span>

                            <span class="input-form">
                                <mat-form-field>
                                    <label>{{constant?.new_password}}</label>
                                    <input matInput type="password" formControlName="newPassword">

                                    <mat-error *ngIf="resetForm.get('newPassword')?.errors?.pattern?.requiredPattern">
                                        {{constant?.errorMsg_password}}
                                    </mat-error>
                                    <mat-error *ngIf="resetForm.get('newPassword')?.invalid && 
                                    (resetForm.get('newPassword')?.dirty || 
                                    resetForm.get('newPassword')?.touched) && 
                                    resetForm.get('newPassword')?.errors?.required">
                                        {{constant?.password_required}}
                                    </mat-error>
                                </mat-form-field>
                            </span>
                        </div>

                        <div class="reset-button-container">
                            <button mat-button *ngIf="!isOTPSent" class="reset-button"
                                (click)="OTPVerificationHandler()">
                                {{constant?.verify_mail}}
                            </button>
                            <button mat-button *ngIf="isOTPSent" class="reset-button"
                                (click)="passwordResetHandler()">
                                {{constant?.reset_password}}
                            </button>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>
