import { Component, OnInit } from "@angular/core";
import { LoaderService } from "../../services/loader.service";

@Component({
  selector: "app-wave-spinner",
  templateUrl: "./wave-spinner.component.html",
  styleUrls: ["./wave-spinner.component.scss"],
})
export class WaveSpinnerComponent implements OnInit {
  showHideLoader!: boolean;
  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.loaderService.$isLoading.subscribe((load: any) => {
      this.showHideLoader = load;
    });
  }
}
