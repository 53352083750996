import { Component, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { CommandCenter } from "src/app/assets/data/constant-values";
import { ElevateSafetyDetectiveService } from "src/app/shared/services/elevateSafety-detective/elevate-safety-detective.service";

@Component({
  selector: "app-command-center",
  templateUrl: "./command-center.component.html",
  styleUrls: ["./command-center.component.scss"],
})
export class CommandCenterComponent implements OnInit {
  navLinks = CommandCenter.NAVIGATION_LABELS;
  
  constructor(
    private cookieService : CookieService,
    private elevateSafetyDetectiveService : ElevateSafetyDetectiveService
    ) {}

  ngOnInit(): void {
   const userRole = localStorage.getItem('role'); 
   this.elevateSafetyDetectiveService.taskQueueFilterValues.activeState = false;
    this.navLinks = (userRole === 'ROLE_SYSTEM_ADMINISTRATOR') ? CommandCenter.NAVIGATION_LABELS : CommandCenter.NAVIGATION_LABELS_FOR_CP;
  }
}
