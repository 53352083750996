import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from './constants';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class HttpClientHelper {
	private http;
	private readonly baseURL;
	private readonly baseApiUrl;
	private readonly sysAdminApiUrl;

	
	static getBaseURL() {
		return location.origin;
	}

	constructor(
		http: HttpClient,
		private constants: Constants,
		private cookieService:CookieService
	) {
		this.http = http;
		this.baseURL = HttpClientHelper.getBaseURL();
		//this.baseApiUrl = "http://localhost:8080/";
		//this.sysAdminApiUrl = "http://localhost:9090/";
		// this.baseApiUrl = "http://filetracking-env.eba-23dhqf8z.ap-south-1.elasticbeanstalk.com/";
		// this.sysAdminApiUrl = "http://funneladmin-env.eba-rwaawyf8.ap-south-1.elasticbeanstalk.com/";
		// this.baseApiUrl = "https://mv9xt8e2r3.execute-api.us-east-1.amazonaws.com/";
		// this.sysAdminApiUrl = "https://mv9xt8e2r3.execute-api.us-east-1.amazonaws.com/";
		// this.sysAdminApiUrl = "http://funneladmin-env.eba-rwaawyf8.ap-south-1.elasticbeanstalk.com/";
		// this.baseApiUrl = "http://filetrackingenvironment-env.eba-jf7rxmsr.ap-south-1.elasticbeanstalk.com/";
		// this.sysAdminApiUrl = "http://utsdeui36m.execute-api.ap-south-1.amazonaws.com/dev/";
		// this.baseApiUrl = "http://utsdeui36m.execute-api.ap-south-1.amazonaws.com/dev/";		

		// conginito
		// this.sysAdminApiUrl = "http://revampedfiletracking-env.eba-zms7stze.ap-south-1.elasticbeanstalk.com/";
		// this.baseApiUrl = "http://revampedfiletracking-env.eba-zms7stze.ap-south-1.elasticbeanstalk.com/";		

		// this.sysAdminApiUrl = "http://a3085a232fa8f4d1f921a53f0c122444-b5092e77be9b293a.elb.ap-south-1.amazonaws.com/";
		// this.baseApiUrl = "http://a369ad44b2e094cc291f21f7b6176788-dde57ae4657a0881.elb.ap-south-1.amazonaws.com/";		

		this.sysAdminApiUrl = "https://sysadmin-funnel-1.dev-innominds.com/";
		this.baseApiUrl = "https://filetracking-funnel-1.dev-innominds.com/";		

		
		
	}

	handleError(error:any) {
		if (error.status === 401) {
			return throwError(error || 'Server error');;
		}
		return throwError(error || 'Server error');
	}

	createHttpHeaders(isFile = false): HttpHeaders {
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json');
		if (!isFile) {
			headers = headers.append('Content-Type', 'application/json');
		}else{
			headers = headers.append('Content-Type', 'text/csv');
		}
		if (this.cookieService.check(this.constants.TOKEN_HEADER)) {
			let user:any = localStorage.getItem('USER');
			headers = headers.append(this.constants.TOKEN_HEADER,this.cookieService.get(this.constants.TOKEN_HEADER));
			headers = headers.append(this.constants.API_TOKEN,this.cookieService.get(this.constants.API_TOKEN));
			headers = headers.append(this.constants.USER_NAME,user);
		}
		else {
			headers = headers.append(this.constants.TOKEN_HEADER, '');
			headers = headers.append(this.constants.API_TOKEN, '');
			headers = headers.append(this.constants.USER_NAME, '');
		}
		return headers;
	}
	createHttpHeadersCognito(isFile = false): HttpHeaders {
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json');
		if (!isFile) {
			headers = headers.append('Content-Type', 'application/json');
		}else{
			headers = headers.append('Content-Type', 'text/csv');
		}
		if (this.cookieService.check(this.constants.TOKEN_HEADER)) {
			let user:any = localStorage.getItem('USER');
			headers = headers.append(this.constants.TOKEN_HEADER,this.cookieService.get(this.constants.TOKEN_HEADER));
			headers = headers.append(this.constants.API_TOKEN,this.cookieService.get(this.constants.API_TOKEN));
			headers = headers.append(this.constants.USER_NAME,user);
		}
		else {
			headers = headers.append(this.constants.TOKEN_HEADER, '');
			headers = headers.append(this.constants.API_TOKEN, '');
			headers = headers.append(this.constants.USER_NAME, '');
		}
		return headers;
	}
	createHttpHeadersForImage(isFile = false): HttpHeaders {
		let headers = new HttpHeaders();
		headers = headers.append('Accept', '*/*');
		if (!isFile) {
			headers = headers.append('Content-Type', 'image/png');
		}
		if (this.cookieService.check(this.constants.TOKEN_HEADER)) {
			let user:any = localStorage.getItem('USER');
			headers = headers.append(this.constants.TOKEN_HEADER,this.cookieService.get(this.constants.TOKEN_HEADER));
			headers = headers.append(this.constants.API_TOKEN,this.cookieService.get(this.constants.API_TOKEN));
			headers = headers.append(this.constants.USER_NAME,user);
		}
		return headers;
	}
	createHttpHeadersUpload(): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.append('Accept', 'text/csv');
        if (this.cookieService.check(this.constants.TOKEN_HEADER)) {
			let user:any = localStorage.getItem('USER');
            headers = headers.append(this.constants.TOKEN_HEADER,this.cookieService.get(this.constants.TOKEN_HEADER));
			headers = headers.append(this.constants.API_TOKEN,this.cookieService.get(this.constants.API_TOKEN));
			headers = headers.append(this.constants.USER_NAME, user);
        }
        return headers;
    }
	createHttpHeadersUploads(): HttpHeaders {
        let headers = new HttpHeaders();
         headers = headers.append('Accept', '*');
        if (this.cookieService.check(this.constants.TOKEN_HEADER)) {
			let user:any = localStorage.getItem('USER');
            headers = headers.append(this.constants.TOKEN_HEADER,this.cookieService.get(this.constants.TOKEN_HEADER));
			headers = headers.append(this.constants.API_TOKEN,this.cookieService.get(this.constants.API_TOKEN));
			headers = headers.append(this.constants.USER_NAME,user);
        }
        return headers;
    }
	get(url:any, params = {}): Observable<any> {
		url = this.baseApiUrl + url;
		const headers = this.createHttpHeaders();
		return this.http
			.get(url, { headers: headers, params: params, observe: 'response' })
			.pipe(catchError(this.handleError));
	}
	getS(url:any, params = {}): Observable<any> {
		url = this.sysAdminApiUrl + url;
		const headers = this.createHttpHeaders();
		return this.http
			.get(url, { headers: headers, params: params, observe: 'response' })
			.pipe(catchError(this.handleError));
	}
	getCSV(url:any, params = {}): Observable<any> {
		url = this.sysAdminApiUrl + url;
		const headers = this.createHttpHeaders();
		return this.http
			.get(url, { headers: headers, params: params, observe: 'response',responseType:'text' as 'json'  })
			.pipe(catchError(this.handleError));
	}
	uploadFile(url: any, data = ''): Observable<any> {
        url = this.sysAdminApiUrl + url;
        const headers = this.createHttpHeadersUpload();
        return this.http
            .post(url, data, { headers: headers, observe: 'events', responseType: 'text' as any, reportProgress: true })
            .pipe(catchError(this.handleError));
    }
	uploadTask(url: any, data = ''): Observable<any> {
        url = this.sysAdminApiUrl + url;
        const headers = this.createHttpHeadersUploads();
        return this.http
            .post(url, data, { headers: headers, observe: 'events', responseType: 'text' as any, reportProgress: true })
            .pipe(catchError(this.handleError));
    }
	
	getBinary(url:any, params = {}): Observable<any> {
		url = this.baseApiUrl + url;
		const headers = this.createHttpHeadersForImage();
		return this.http
			.get(url, { headers: headers, params: params, observe: 'response', responseType: 'blob' })
			.pipe(catchError(this.handleError));
	}
	getH(url:any, params = {}): Observable<any> {
		const headers = this.createHttpHeaders();
		return this.http
			.get(url, { headers: headers, params: params, observe: 'response' })
			.pipe(catchError(this.handleError));
	}

	post(url:any, data = '', params = {}): Observable<any> {
		url = this.baseApiUrl + url;
		const headers = this.createHttpHeaders();
		const body = JSON.stringify(data);
		return this.http
			.post(url, body, { headers: headers, observe: 'response', params: params })
			.pipe(catchError(this.handleError));
	}
	postLogin(url:any, data = '', params = {}): Observable<any> {
		url = this.baseApiUrl + url;
		const headers = this.createHttpHeadersCognito();
		const body = JSON.stringify(data);
		return this.http
			.post(url, body, { headers: headers, observe: 'response', params: params })
			.pipe(catchError(this.handleError));
	}
	postS(url:any, data = '', params = {}): Observable<any> {
		url = this.sysAdminApiUrl + url;
		const headers = this.createHttpHeaders();
		const body = JSON.stringify(data);
		return this.http
			.post(url, body, { headers: headers, observe: 'response', params: params })
			.pipe(catchError(this.handleError));
	}

	postH(url:any, data = ''): Observable<any> {
		url = this.baseApiUrl + url;
		const headers = this.createHttpHeaders();
		const body = data === '' ? '{}' : JSON.stringify(data);
		return this.http
			.post(url, body, { headers: headers, observe: 'response' })
			.pipe(catchError(this.handleError));
	}

	postFile(url:any, data = ''): Observable<any> {
		url = this.baseApiUrl + url;
		const headers = this.createHttpHeaders(true);
		return this.http
			.post(url, data, { headers: headers, observe: 'events', reportProgress: true })
			.pipe(catchError(this.handleError));
	}
	putFile(url:any, data = ''): Observable<any> {
		url = this.baseApiUrl + url;
		const headers = this.createHttpHeaders(true);
		return this.http
			.put(url, data, { headers: headers, observe: 'events', reportProgress: true })
			.pipe(catchError(this.handleError));
	}

	put(url:any, data = '', params = {}): Observable<any> {
		url = this.baseApiUrl + url;
		const headers = this.createHttpHeaders();
		const body = JSON.stringify(data);
		return this.http
			.put(url, body, { headers: headers, observe: 'response', params: params })
			.pipe(catchError(this.handleError));
	}

	putH(url:any, data = ''): Observable<any> {
		const headers = this.createHttpHeaders();
		const body = JSON.stringify(data);
		return this.http
			.put(url, body, { headers: headers, observe: 'response' })
			.pipe(catchError(this.handleError));
	}

	// delete(url:any, params = {}): Observable<any> {
	// 	url = this.baseApiUrl + url;
	// 	const httpHeaders = this.createHttpHeaders();
	// 	return this.http
	// 		.delete(url, { headers: httpHeaders, params: params, observe: 'response' })
	// 		.pipe(catchError(this.handleError));
	// }

	// deleteH(url:any, params = {}): Observable<any> {
	// 	const httpHeaders = this.createHttpHeaders();
	// 	return this.http
	// 		.delete(url, { headers: httpHeaders, params: params, observe: 'response' })
	// 		.pipe(catchError(this.handleError));
	// }
}

