import { Component, OnInit } from '@angular/core';
import { CONSTANTS } from 'src/app/assets/data/i18';

@Component({
  selector: 'app-system-administration',
  templateUrl: './system-administration.component.html',
  styleUrls: ['./system-administration.component.scss']
})
export class SystemAdministrationComponent implements OnInit {
  constants: any = CONSTANTS;
  
  constructor() { 
    // do nothing.
  }

  ngOnInit(): void {
    // do nothing.
  }

}
