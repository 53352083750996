<div class="taskDetailsContainer" *ngIf="taskDetails">
    <div class="fileReviewDetectedBlock" [ngClass]="isCriteriaCollapsed ? 'collapseCriteria' : 'expandCriteria'">
        <app-file-review (recallTaskDetail)="recallTaskDetail()" (columnCollapsed)="columnCollapsed($event)" [taskDetails]="taskDetails"
        (isEditEnable)="isEditEnable($event)">
        </app-file-review>
    </div>
    <div class="taskQueueViewerBlock" [ngClass]="isCriteriaCollapsed ? 'collapseTaskQueue' : 'expandTaskQueue'">
        <app-task-queue-viewer [taskDetails]="taskDetails" (claimedStatus)="claimedStatus($event)">
        </app-task-queue-viewer>
    </div>
</div>