import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { JsonEditorComponent, JsonEditorOptions } from "ang-jsoneditor";
import { takeWhile } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/authentication/auth.service";
import { ElevateSafetyDetectiveService } from "src/app/shared/services/elevateSafety-detective/elevate-safety-detective.service";
import { CookieService } from "ngx-cookie-service";
import { CONSTANTS } from "src/app/assets/data/i18";
import { ElevateSafety } from "src/app/assets/data/constant-values";
import {ThemePalette} from '@angular/material/core';
@Component({
  selector: "app-task-queue-viewer",
  templateUrl: "./task-queue-viewer.component.html",
  styleUrls: ["./task-queue-viewer.component.scss"],
})
export class TaskQueueViewerComponent implements OnInit {
  constants: any = CONSTANTS;
  taskClamimedMsg: string = CONSTANTS.taskClamimedMsg;
  taskUnclamimedMsg: string = CONSTANTS.taskUnclamimedMsg;
  taskChangeErrorMsg: string = CONSTANTS.taskChangeErrorMsg;
  invalidAssignee: string = CONSTANTS.invalidAssignee;
  @Input() taskDetails: any;
  @Output() claimedStatus = new EventEmitter();
  color = "primary";
  checked = false;
  disabled = false;
  documentId: any;
  private alive = true;
  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent | any;
  options = new JsonEditorOptions();
  taskType: any;
  assignee: any;
  disableToggle: boolean = false;
  fileName: string = "";
  userName: any = localStorage.getItem('USER');
  role = localStorage.getItem('role');
  taskViewerLinks: any[] = ElevateSafety.TASK_VIEWER_NAVIGATION_LABELS;
  viewBasedUpon: string = "";
  downloadAttachments: any[] = [];
  showCaseNarrative: any;
  background: ThemePalette = undefined;
  classFocus: any = {
    icon0: true,
    icon1: false,
    icon2: false
  }
  
  constructor(
    public dialog: MatDialog,
    public elevateSafetyDetectiveService: ElevateSafetyDetectiveService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.viewBasedUpon = this.taskViewerLinks[0].value;
    this.documentId = this.route.snapshot.paramMap.get("document-id");
    this.taskDetailsViewerTypeHandler();
    this.checked = this.taskDetails?.isClaimed ? true : false;
    this.taskType = this.taskDetails?.taskType;
    this.assignee = this.taskDetails?.assignee;
    this.fileName =
      this.taskDetails?.transcriptsUrl.split("/")[
      this.taskDetails?.transcriptsUrl.split("/").length - 1
      ];
    if (this.assignee && this.userName !== this.assignee && this.role != 'ROLE_SYSTEM_ADMINISTRATOR') {
      this.disableToggle = true;
    }
  }

  isClaimed(event: any) {
    const stat = this.checked ? "Y" : "N";
    this.elevateSafetyDetectiveService
      .claimOrUncliamTask(this.documentId, stat)
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (data) => {
          if (data.body?.statusCode == 200) {
            this.checked = event.source?.checked;
            const heading = this.checked
              ? this.taskClamimedMsg
              : this.taskUnclamimedMsg;
            this.authService.showSuccessErrorPopup("success", heading);
            this.claimedStatus.emit(this.checked);
          } else {
            this.checked = !event.source?.checked;
            this.authService.showSuccessErrorPopup(
              "error",
              data.body?.statusMessage
            );
            this.claimedStatus.emit(this.checked);
          }
        },
        (error) => {
          this.checked = !event.source?.checked;
          this.authService.handleError(error);
        }
      );
  }

  loadTree(data: any) {
    this.options.expandAll = true;
    this.options.mode = "tree";
    this.options.schema = data;
    this.options.statusBar = false;
    this.options.onChange = () => console.log(this.editor.get());
    this.options.onEditable = () => { return false }
  }

  downloadFile(attachments: any) {
    if (this.taskDetails?.transcriptsUrl && !attachments) {
      this.fileDownloadHandler(this.taskDetails?.transcriptsUrl);
    } else if (attachments) {
      this.fileDownloadHandler(attachments);
    }
  }

  // to download any file
  fileDownloadHandler(file: any): void {
    const link = document.createElement("a");
    link.download = "name";
    link.href = file ? file : "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // to view the task on selected type
  selectedViewerType(viewerType: any): void {
    this.viewBasedUpon = viewerType?.value;
    switch (viewerType?.value) {
      case 'case-narrative': {
        this.changeClass(true, false , false);
        break;
      }
      case 'full-source-document': {
        this.changeClass(false, true , false);
        break;
      }
      case 'attachments': {
        this.changeClass(false, false , true);
        break;
      }
    }
  }

  changeClass(first: boolean, second: boolean, third: boolean): void {
    this.classFocus = {
      icon0: first,
      icon1: second,
      icon2: third
    }
  }

  // to get the data for all three viewer tabs 
 async taskDetailsViewerTypeHandler(): Promise<void> {
    this.elevateSafetyDetectiveService.chatDetails(this.documentId)
      .pipe(takeWhile(() => this.alive))?.subscribe((data: any) => {
        if (data?.status === 200) {
          this.downloadAttachments = data?.body['attachments'];
          this.showCaseNarrative = data?.body['caseNarrative'];
          this.loadTree(JSON?.parse(data?.body['chatTranscript']));
        }
      }, (error) => this.authService?.handleError(error))
  }
}
