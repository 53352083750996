<div class="formFields">
  <div class="searchFilterContainer">
    <div class="uplaodBox">
      <span>
        <p class="fileName">{{ uploadedFileName }}</p>
      </span>
      <input
        #uploadFile
        type="file"
        hidden
        accept=".xml"
        (change)="uploadFileHandler($event)"
      />
      <button class="browseBTN" mat-button (click)="uploadFile.click()">
        <img src="../../../app/assets/images/Upload-icon.png" alt="" />
        {{ constant?.upload }}
      </button>
    </div>
    <button mat-button class="downloadBTN" (click)="uploadAndDownloadFile()">
      <img src="../../../app/assets/images/Upload-icon.png" alt="" />
      {{ constant?.uploadfile }}
    </button>
  </div>
</div>
