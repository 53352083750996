import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { TaskQueueComponent } from './components/task-queue/task-queue.component';
import { FileTrackingComponent } from './components/file-tracking/file-tracking.component';
import { SharedModule } from './shared/shared.module';
import { TaskDetailComponent } from './components/task-detail/task-detail.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { ErrorComponentComponent } from './components/error-component/error-component.component';
import { HttpClientHelper } from './app.httpclient';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TaskQueueViewerComponent } from './components/task-queue-viewer/task-queue-viewer.component';
import { CommandCenterComponent } from './components/command-center/command-center.component';
import { ReportMetricsComponent } from './components/report-metrics/report-metrics.component';
import { SystemAdministrationComponent } from './components/system-administration/system-administration.component';
import { DocumentAttributeDetectionComponent } from './components/document-attribute-detection/document-attribute-detection.component';
import { RetrospectiveQcSamplingComponent } from './components/retrospective-qc-sampling/retrospective-qc-sampling.component';
import { TooltipsComponent } from './components/tooltips/tooltips.component';
import { Constants } from './constants';
import { CookieService } from 'ngx-cookie-service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { CommonModule, DatePipe } from '@angular/common';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { FileTrackingDialogboxComponent } from './components/file-tracking-dialogbox/file-tracking-dialogbox.component';
import { FileReviewComponent } from './components/file-review/file-review.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    HeaderComponent,
    TaskQueueComponent,
    FileTrackingComponent,
    TaskDetailComponent,
    UserInfoComponent,
    ErrorComponentComponent,
    TaskQueueViewerComponent,
    CommandCenterComponent,
    ReportMetricsComponent,
    SystemAdministrationComponent,
    DocumentAttributeDetectionComponent,
    RetrospectiveQcSamplingComponent,
    TooltipsComponent,
    UserManagementComponent,
    AddUserComponent,
    FileTrackingDialogboxComponent,
    FileReviewComponent,
    ForgotPasswordComponent
  ],
  imports: [
    BrowserModule,
    FormsModule, 
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgJsonEditorModule,
    CommonModule
  ],
  providers: [
		HttpClientHelper,
		Constants,
		CookieService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
