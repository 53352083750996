<div class="intake-status-container">
  <div class="heading-block">
    <h1 class="intake-status">{{ constants?.intakeStatusHeading }}</h1>
    <button class="close-icon" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="content-block">
    <div class="content-left">
      <span *ngFor="let content of leftContent">
        <h2>{{ content?.heading }}</h2>
        <p>{{ content?.data }}</p>
      </span>
    </div>

    <div class="content-right">
      <span *ngFor="let content of rightContent">
        <h2>{{ content?.heading }}</h2>
        <p *ngIf="content?.data">{{ content?.data }}</p>
      </span>
    </div>
  </div>
</div>
