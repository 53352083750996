<div class="systemAdminContainer">
  <div class="modulesName" id="moduleHeadingList">
    <mat-action-list>
      <mat-list-item
        [routerLink]="['file-attribute']"
        [routerLinkActive]="['is-active']"
      >
        {{ constants?.file_attribute_detection }}
      </mat-list-item>
      <mat-list-item
        [routerLink]="['retrospective-qc']"
        [routerLinkActive]="['is-active']"
      >
        {{ constants?.retrospective_qc_sampling }}
      </mat-list-item>
      <mat-list-item
        [routerLink]="['tool-tip']"
        [routerLinkActive]="['is-active']"
      >
        {{ constants?.tooltips }}
      </mat-list-item>
    </mat-action-list>
  </div>
  <div class="modulesData">
    <router-outlet></router-outlet>
  </div>
</div>
