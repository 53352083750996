import { Component } from "@angular/core";
import { Location } from "@angular/common";

@Component({
  selector: "app-error-component",
  templateUrl: "./error-component.component.html",
  styleUrls: ["./error-component.component.scss"],
})
export class ErrorComponentComponent {
  constructor(private location: Location) {
    // do nothing.
  }

  goBack(): void {
    this.location.back();
  }
}
