<div class="userManagementContainer containerPadding">
  <h2 class="heading">{{ constants?.userManagement }}</h2>

  <form [formGroup]="userManagementForm">
    <div class="actionRow">
      <span class="searchFilterContainer">
        <mat-form-field appearance="outline">
          <input
            matInput
            type="text"
            placeholder="Search"
            formControlName="searchByValue"
            (keyup)="searchUser($event)"
          />
          <img
            src="../../../app/assets/images/search-icon.svg"
            alt="search"
            title="search-icon"
            height="15px"
            width="15px"
          />
        </mat-form-field>
      </span>

      <div class="rightBox">
        <span
          class="clearBTN"
          id="clearBTN"
          *ngIf="isFilter"
          (click)="clearFilter()"
        >
          {{ constants?.clear_filters }}
        </span>
      </div>
    </div>
  </form>

  <div class="table-container tableContainer" id="tableContainer">
    <table mat-table [dataSource]="dataSource" class="tableCSS" matSort>
      <caption></caption>
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ constants?.user }}
        </th>
        <td mat-cell *matCellDef="let element" title="{{ element.username }}">
          {{ element.username }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>
          {{ constants?.emailid }}
        </th>
        <td mat-cell *matCellDef="let element" title="{{ element.email }}">
          {{ element.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef>
          {{ constants?.roles }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element.role === "CASE_PROCESSOR"
              ? "Case Processor"
              : "System Administrator"
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="enable">
        <th mat-header-cell *matHeaderCellDef>
          {{ constants?.enable }}
        </th>
        <td mat-cell *matCellDef="let element">
          <section class="example-section">
            <mat-slide-toggle
              [color]="color"
              [checked]="element.enable"
              (change)="changeStatus($event, element)"
            >
            </mat-slide-toggle>
          </section>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <img
            src="../../../app/assets/images/edit-icon-new.svg"
            alt="edit user"
            title="edit icon"
            (click)="openUserForm(element)"
          />
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div class="paginationRow">
    <div id="pageNumber">
      <p>
        {{ constants?.showing }} {{ lastPageDataCount + 1 }}
        {{ constants?.of }} {{ totalTask }} {{ constants?.results }}
      </p>
      <p>Page No: {{ currentPageNumber + 1 }}</p>
    </div>
    <mat-paginator
      #paginator
      [length]="totalTask"
      [pageSize]="10"
      [pageSizeOptions]="pageSizeOptions"
      (page)="getPage($event)"
    >
    </mat-paginator>
  </div>
</div>
