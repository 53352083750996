<div class="addUserContainer">
  <div class="heading">
    <h2>{{ heading }}</h2>
    <mat-icon (click)="close()">close</mat-icon>
  </div>

  <form [formGroup]="userManagementForm">
    <div class="userForm">
      <div class="user-details-non-editable">
        <div class="username">
          <span class="detail-heading">{{ constants?.user }}</span>
          <span> {{ userDetails.firstName }} {{ userDetails.lastName }} </span>
        </div>
        <div class="email-id">
          <span class="detail-heading">{{ constants?.emailID }}</span>
          <span>{{ userDetails.email }}</span>
        </div>
      </div>

      <!-- User Group/Role -->
      <div class="inputField searchFilterContainer" id="roleSelect">
        <h2>{{ constants?.User_Group }}:</h2>
        <mat-form-field appearance="outline" class="dropdown_icon">
          <mat-select
            formControlName="userRole"
            placeholder="{{ constants?.userGroupPlaceholder }}"
            ngDefaultControl
          >
            <mat-option *ngFor="let role of userRoles" [value]="role.value">
              {{ role.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Enable User -->
    <div class="enableUser">
      <mat-checkbox formControlName="enable" ngDefaultControl>
        {{ constants?.enable }}
      </mat-checkbox>
    </div>

    <div class="actionButtons">
      <button mat-button class="cancelBTN" (click)="close()">
        {{ constants?.cancel }}
      </button>
      <button mat-button class="saveBTN" (click)="save()">
        {{ constants?.save }}
      </button>
    </div>
  </form>
</div>
