import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PaginationConstants, TableColumnNames, UserManagementInterface } from "src/app/assets/data/constant-values";
import { CONSTANTS } from "src/app/assets/data/i18";
import { CommandCenterService } from "src/app/shared/services/command-center/command-center.service";
import { AddUserComponent } from "../add-user/add-user.component";
import { takeWhile } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/authentication/auth.service";

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class UserManagementComponent implements OnInit {
  constants: any = CONSTANTS;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild(MatSort) sort: MatSort | any;
  displayedColumns: string[] = TableColumnNames.USER_MANAGEMENT_TABLE;
  color: string = CONSTANTS.toggleBTNcolor;
  itemPerPage: number = PaginationConstants.itemPerPage;
  pageSizeOptions: number[] = PaginationConstants.pageSizeOptions;
  currentPageNumber: number = PaginationConstants.currentPageNumber;
  lastPageDataCount: number = PaginationConstants.lastPageDataCount;
  totalTask!: number;
  dataSource = new MatTableDataSource<any>();
  userManagementForm!: FormGroup;
  usersName: any;
  alive = true;
  sortedData!: any[];
  filterDictionary = new Map<string, string>();
  isFilter: boolean = false;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private CommandCenterService: CommandCenterService,
    private authService: AuthService
  ) {
    this.userManagementForm = this.fb.group({
      searchByValue: new FormControl(""),
    });
  }

  ngOnInit(): void {
    this.getAllUsers();
    this.applyFilterPredicate();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllUsers(): any {
    this.CommandCenterService.getAllUser()
      .pipe(takeWhile(() => this.alive))
      .subscribe((data: any) => {
        this.dataSource.data = data.body;
        this.dataSource.data.forEach((names) => {
          this.usersName = names.firstName + " " + names.lastName;
          names["username"] = this.usersName;
        });
        this.totalTask = data.body.length;
        this.getPageTotalCount()
      });
  }

  searchUser(event: any) {
    const filterValue = this.userManagementForm.value.searchByValue;
    if (event.target?.value !== "") {
      this.isFilter = true;
      this.filterDictionary.set('username', filterValue?.trim().toLowerCase());
      this.dataSource.filter = JSON.stringify(Array.from(this.filterDictionary.entries()));
    } else {
      this.isFilter = false;
    }
  }

  // open dialog for newUser/EditUser
  openUserForm(user: {}): void {
    const dialogRef = this.dialog.open(AddUserComponent, {
      id: "newUserComponent",
      data: user,
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getAllUsers();
    });
  }

  // change user account status enable/disable
  changeStatus(event: any, user: { email: "" }): void {
    const payload = {
      username: user?.email,
      enabled: event.checked,
    };
    this.CommandCenterService.changeUserStatus(payload)
      .pipe(takeWhile(() => this.alive))
      .subscribe((data: any) => {
        let modalStatus = data["body"].statusCode === 200 ? "success" : "error";
        this.authService.showSuccessErrorPopup(
          modalStatus,
          data["body"].statusMessage
        );
      });
  }

  // pagination
  getPage(event: any): void {
    this.totalTask = event.length;
    this.itemPerPage = event.pageSize;
    this.currentPageNumber = event.pageIndex;
    this.getPageTotalCount();
  }

  getPageTotalCount() {
    this.dataSource["_renderData"].subscribe((ele: any) => {
      this.lastPageDataCount = 0;
      this.lastPageDataCount = ele.length - 1;
    });
  }

  // clear searched keywords
  clearFilter(): void {
    this.userManagementForm.reset();
    this.filterDictionary.clear();
    this.dataSource.filter = "";
    this.isFilter = false;
  }

  // filter the table
  applyFilterPredicate(): void {
    const me = this;
    this.dataSource.filterPredicate = function (record: any, filter: any) {
      let map: any = new Map(JSON.parse(filter));
      let isMatch = false;
      for (let [key, value] of map) {
        if (key === 'username') {
          isMatch = record[key as keyof UserManagementInterface]?.trim().toLowerCase().indexOf(value) != -1
        } else {
          isMatch = (record[key as keyof UserManagementInterface] == value?.value)
        }
        if (!isMatch) return false;
      }
      return isMatch;
    }
  }
}
