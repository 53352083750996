export class CONSTANTS {
    static appTitle = 'Elevate Safety Detective';
    static toggleBTNcolor = "#4B4B4B"; // toggle button color
    static elevateSafety_detective = "ElevateSafety Detective";
    static command_center = "Command Center";
    static logout = "Logout";
    static userFullName = "USER_FULL_NAME";
    static USERName = 'USER';
    static USERRole = 'role';
    static dropdown_data = 'DROPDOWN_DATA';
    static header_fileTraking_heading = 'ElevateSafety™ Detective by ConvergeHEALTH';
    static header_admin_heading = 'Command Center';
     
    // Login Page
    static email = "Email";
    static login = "Login";
    static verify_mail = "Send password";
    static forgot_password= "Forgot password?"
    static reset_password = "Reset Password";
    static email_required = "Email is required*";
    static password_received = "Password Received";
    static new_password = "New Password";
    static OTPErrorMsg = "One time password required"
    static errorMsg_email = "Please enter a valid email address";
    static password = "Password";
    static password_required = "Password is required";
    static errorMsg_password = `Password length must be greater than or equal to 8 and it must contain one or more uppercase ,
    lowercase , numeric and special characters.`;
    static tokenExpire = "Your Session has expired. You will be logged out soon.";
    static serverError = "Internal error. Please try again.";
    static newPassword = "New Password";
    static newPassword_placeHolder ="Enter New Password";
    static reset = "Reset Password";
    static id = "ID";
    static document_type = "Document Type";
    static source = "Source";
    static source_type = "Source Type";
    static task_type = "Task Type";
    static assigned_to_me = "Assigned to me";
    static clear_filters = "Clear All Filters";
    static intake_date = "Intake Date";
    static priority = "Priority";
    static assignee = "Assignee";
    static showing = "Showing";
    static of = "of";
    static results = "Results";
    static select_document_type = "Select source(s)"
    static select_task_type = "Select task type(s)"

    static file_search = "File Search";
    static receipt_date = "Receipt Date";
    static safetyTransmission = "Safety Transmission";
    static qualityTransmission = "Quality Transmission";
    static download_inbound = "Download (Inbound)";
    static download_outbound = "Download (Outbound)";
    static inbound_file_name = "Inbound File Name";
    static int = "INT";
    static aa = "AA";
    static fr = "FR";
    static t = "E2BT";
    static rq = "RQ";
    static disposition = "Disposition";
    static assignee_filter_heading = "Users";
    static intakeStatusHeading = "Intake Status";
    static fileNameHeading = "FileName";
    static errorRejectHeading = "Error/Reject Message";
    static timestampGMTHeading = "Timestamp(GMT)";
    static supportInformationHeading = "Support Information";

    static file_attribute_detection = "File attribute detection";
    static retrospective_qc_sampling = "Retrospective Quality sampling";
    static tooltips = "Tooltips";

    static field = "Field";
    static manual_review_enabled = "Manual Review Enabled?";
    static automated_review_enabled = "Automated Detection Enabled?";
    static lower_bound = "Lower Bound % ";
    static upper_bound = "Upper Bound % ";
    static action = "Action";
    static save = "Save";
    static cancel = "Cancel";

    static frequency = "Frequency";
    static period_start  = "Period End ";
    static sampling_rate = "Sampling Rate %";

    static item = "Item";
    static text = "Text";
    static case_validity_defination = "Adverse Event Definition";
    static product_complaint_definationa = "Product Quality Complaint Definition";
    static support_information = "Support Information"

    static addUser = "Add User";
    static firstname = "First name";
    static lastname = "Last name";
    static emailid = "Email id";
    static phone_number = "Phone Number";
    static user_Role = "User Role";
    static User_ID = "User ID";
    static User_Group = "User Groups";
    static clear_user_role = "Clear user role(s)";
    static enable = "Enable";

    static selectAll = "Select All";
    static apply = "Apply";
    static clear = "Clear";

    // User Management Component
    static userDetailsHeading = "Edit User";
    static addUsersHeading = "Add User";
    static userManagement = "User Management";
    static users = "Users";
    static addNew = "Add New ";
    static user = "User";
    static emailID = "Email ID";
    static roles = "Role(s)";
    
    // Dialog box headings starts
    static taskCompletedMsg = "Task is successfully completed.";
    static taskSavedMsg = "Task is successfully saved.";
    static taskClamimedMsg = "Task is successfully claimed.";
    static taskUnclamimedMsg = "Task is successfully unclaimed.";
    static taskChangeErrorMsg = "We cannot change the task for different users.";
    static invalidAssignee = "Invalid user is detected / undefine user.";
    static invalidDownloadUrl = "Download file not found";
    static upperBoundErrorMsg = "Upper bound should be greater than lower bound.";
    static attributeSavedMsg = "File Attribute is successfully saved.";
    static tooltipSaveMsg = "Tootips attribute is successfully saved.";
    static retrospectiveSaveMsg = "Retrospective Attribute is successfully saved.";
    static retrospectiveErrorMsg = "Period End is required for weeekly and Biweekly frequency to save";
    static unsavedChangesMsg = "This task has not been completed.";
    static pageLeaveMsg = "Are you sure you would like to close this task?";
    static wrongCredentials = "Invalid Username or password.";
    // Dialog box headings ends

    //report-metrics constants
    static reconciliation = "Reconciliation";
    static reconciliationmessage = "The Reconciliation Report contains a summary of all the steps taken during case processing from intake to sending the case off to your safety system.";
    static adjudication = "Adjudication";
    static Filter = "Filter By";
    static timePeriod = "Time Period";
    static date = "Date";
    static download = "Download";
    static adjudicationmessage = 'The Adjudication Report provides details on the user and application actions asserting the presence or absence of safety information in files.';
    static ID = "ID(s)";
    static upload = "Select file";
    static taskAging = "Task Aging";
    static taskAgingmessage = "This report tracks tasks assigned to user and shows how long the tasks have been into the work queue";
    static applicationSettings = "Application Settings";
    static applicationSettingsmessage = "This report shows a log of each user actions taken on configurable parameters in the application.";

    // User information screen.
    static hello = "Hello";
    static welcomeMSG = "Welcome to the";
    static elevateText = "Elevate";
    static safetyText = "ElevateSafety";
    static detectiveText = "Detective"
    static roleDependingMSG = "Depending on your assigned role(s), you can:";
    static learnMoreMSG = "Click (?) to visit the help and learn more."

    static claim = "Claim";
    static fileName = "oc3_chatbot_transcript_test_file.xml";

    static uploadFileErrorMSG = "No document id found in uploaded file.";
    static emptyFileMSG = "No file selected";

     // i-Icon descriptions for system-admin screens
     static manualReviewEnabledMSG = "Checked when manual review is available for a corresponding attribute";
     static automaticDetectionEnabledMSG = "Checked when automated system review is performed for a corresponding attribute";
     static lowerBoundMSG = "Automated assessment confidence scores above this threshold but below the “Upper Bound” result in manual review tasks with suggested attributes. Values below this value lead to review tasks without suggested attributes";
     static upperBoundMSG = "Automated assessment confidence scores above this threshold result in automated processing";
     static frequencyMSG = "Frequency at which previously reviewed files are selected for Retrospective QC";
     static periodEndMSG = "Day of the week for which the sampling period ends";
     static samplingRateMSG = "% of overall files within the sampling period selected for Retrospective QC";

    //  file review components
    static previousPageHeading = "Back to queue";
    static collapse = "Collapse";
    static expand = "Expand";
    static eventDetectionHeading = "Safety Information Detection";
    static productQualityComplaintDetectionHeading = "Product Quality Complaint Detection";
    static completeTaskHeading = "Complete Task";
    static fileReviewHeading = "File Review";
    static eventDetectedHeading = "Safety Information  Detected"; 
    static noEventDetectedHeading = "No Safety Information Detected"; 
    static suspectProductDetectionHeading = "Suspect Product Detection";
    static companyProductDetectedHeading = "Company Product Detected";
    static otherManufacturerProductDetectedHeading = "Other Manufacturer Product Detected";
    static unknownifCompanyProductDetectedHeading = "Unknown if Company Product Detected";
    static noSuspectProductDetectedHeading = "No Suspect Product Detected";
    static patientDetectionHeading = "Patient Detection";
    static patientDetectedHeading = "Patient Detected";
    static noPatientDetectedHeading = "No Patient Detected";
    static reporterDetectionHeading = "Reporter Detection";
    static reporterDetectedHeading = "Reporter Detected";
    static noReporterDetectedHeading = "No Reporter Detected";
    static productQualityComplaintDetectedHeadingHeading = "Product Quality Complaint Detected";
    static noProductQualityComplaintDetectedHeading = "No Product Quality Complaint Detected";

    static fileHeading = "File";
    static uploadfile ="Upload";

    static attachment = "Attachment";
}