<div class="dialogBox">
  <!-- only show for dirty-check dialog-box -->
  <span class="closeBTN" (click)="close()">
    <mat-icon class="iconSize">close</mat-icon>
  </span>

  <div class="headingDespBlock" [ngClass]="data?.status ? 'mb_30' : 'mb_80'">
    <h2 class="headingRow">
      {{ data?.heading }}
    </h2>
    <p class="descriptionRow" *ngIf="data?.description">
      {{ data?.description }}
    </p>
  </div>

  <div class="confirmationRow">
    <button mat-button class="cancelBTN" (click)="close()" *ngIf="data?.cancelBTN">
      {{ data?.cancelBTN }}
    </button>
    <button mat-button class="saveBTN" (click)="save()" *ngIf="data?.saveBTN">
      {{ data?.saveBTN }}
    </button>

    <!-- only for Success/Warning/Error image -->
    <div class="confirmRowImage" *ngIf="data?.status">
      <span class="imageBox successImage" *ngIf="data?.status === 'success'">
        <img src="../../../../app/assets/images/confirmation.svg" alt="confirmation-img" title="confirmation-img" />
      </span>
      <span class="imageBox errorImage" *ngIf="data?.status === 'error'">
        <mat-icon class="iconSize">close</mat-icon>
      </span>
      <span class="imageBox warningImage" *ngIf="data?.status === 'warning'">
        <mat-icon class="iconSize">warning</mat-icon>
      </span>
    </div>
  </div>
</div>
